import VatService from './VatService.js';

const PriceService = {
    splitNum: (num) => {
        num = num.toString().split('.');
        num[0] = Number(num[0]);
        if (num[1]) num[1] = Number(`0.${num[1]}`);
        else num[1] = 0;
        return num;
    },
    gamesWithSilver(game) {
        const games = [
            'The War Within',
            'WOTLK Classic',
            'Classic Season of Mastery',
            'Classic Era',
            'Classic Anniversary',
            'Season of Discovery',
        ];
        return games.includes(game);
    },
    formatSilver: (num) => {
        num = num.toString().split('.');
        return Number(num[1]);
    },
    abbreviateNumber: (num) => {
        if (num >= 1000000) {
            return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
        return num;
    },
    calculatePrice: (price, country, extra_gold, currency = 'gold') => {
        // if (extra_gold) {
        //     let p = price / 0.97;
        //     price = p;
        // }
        // const VAT = VatService.calculateVat(country, price);
        // if (VAT.data) {
        //     price = parseInt(VAT.price.toFixed(10), 10);
        // }
        const num = PriceService.splitNum(price);
        if (typeof price === 'string') {
            let number = price.split('.').join('');
            price = number;
        }
        return {
            raw: price,
            gold: PriceService.abbreviateNumber(Math.ceil(num[0])),
            silver: PriceService.formatSilver(num[1].toFixed(2)),
            currency,
        };
    },
};

export default PriceService;
