<template>
    <v-col class="user-exchange-container" cols="12">
        <v-card outlined>
            <v-card-title class="d-flex justify-center">
                <span class="card-title"
                    >I WANT TO RECEIVE GOLD ON:
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                v-on="on"
                                v-bind="attrs"
                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                alt="info icon"
                                style="margin-left: 7px"
                            />
                        </template>
                        <span
                            >Please provide the in-game details of the server and character name you want to send the
                            Exchanged Gold Amount to</span
                        >
                    </v-tooltip>
                </span>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters style="width: 100%">
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="form-group">
                            <v-select
                                v-model="exchange_version"
                                class="ml-4"
                                :items="versions"
                                placeholder="Game"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :append-icon="'mdi-chevron-down'"
                                :rules="[rules.required]"
                                color="primary"
                                :menu-props="{ offsetY: true }"
                                attach
                                @change="updateServerList"
                            >
                                <template v-slot:item="data">
                                    <div class="flex-inline">
                                        <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                    </div>
                                    <div class="flag-text">
                                        {{ data.item }}
                                    </div>
                                </template>
                                <template #selection="{ item }">
                                    <div class="flex-inline">
                                        <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                    </div>
                                    <div class="flag-text">
                                        {{ item }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="form-group">
                            <v-select
                                v-model="exchange_region"
                                :items="regions"
                                class="ml-4"
                                placeholder="Region"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :append-icon="'mdi-chevron-down'"
                                :rules="[rules.required]"
                                color="primary"
                                :menu-props="{ offsetY: true }"
                                attach
                                @change="updateServerList"
                            >
                                <template v-slot:item="data">
                                    <div class="flex-inline">
                                        <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                    </div>
                                    <div class="flag-text">
                                        {{ data.item }}
                                    </div>
                                </template>
                                <template #selection="{ item }">
                                    <div class="flex-inline">
                                        <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                    </div>
                                    <div class="flag-text">
                                        {{ item }}
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                        <div class="form-group">
                            <v-autocomplete
                                v-model="exchange_server"
                                class="ml-4"
                                :items="items"
                                placeholder="Enter server"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :rules="[rules.required]"
                                color="primary"
                                :menu-props="{ offsetY: true }"
                                attach
                                append-icon=""
                                @change="updateExchangeServerFee"
                            >
                            </v-autocomplete>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters style="width: 100%">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <h3 class="exchange-header">
                            Exchanged To:
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span
                                    >This is the MMO gold amount you will receive from Trade-Raid on your chosen
                                    receiving server and character</span
                                >
                            </v-tooltip>
                        </h3>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        offset-md="4"
                        lg="4"
                        offset-lg="4"
                        xl="4"
                        offset-xl="4"
                        align="center"
                        class="mb-105"
                    >
                        <v-text-field
                            type="number"
                            class="disabled-input"
                            id="exchange_amount"
                            v-model="exchange_amount"
                            placeholder="Fixed Gold Amount"
                            outlined
                            height="36px"
                            dense
                            hide-details
                            color="primary"
                            :rules="[rules.required]"
                            readonly
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        offset-md="4"
                        lg="4"
                        offset-lg="4"
                        xl="4"
                        offset-xl="4"
                        align="center"
                    >
                        <!-- <p class="bg-primary gold-dlvry">Delivery time: 24 hours</p> -->
                        <p v-if="$store.state.trade.msg">
                            {{ $store.state.trade.msg }}
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <img
                                        v-on="on"
                                        v-bind="attrs"
                                        src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                        alt="info icon"
                                        style="margin-left: 7px"
                                    />
                                </template>
                                <span
                                    >Trade-Raid is obligated to charge VAT on behalf of Danish Tax Authorities. Gold
                                    amount is reduced based on your country's VAT % rate.</span
                                >
                            </v-tooltip>
                        </p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters style="width: 100%">
                    <v-col cols="12" sm="12" md="5" xl="5">
                        <div class="form-group">
                            <label for="" class="d-flex align-center">
                                Gold Delivery Method
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-on="on"
                                            v-bind="attrs"
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                            alt="info icon"
                                            style="margin-left: 7px"
                                        />
                                    </template>
                                    <span>The gold is sent by Mail directly to your character's in-game mailbox</span>
                                </v-tooltip>
                            </label>
                            <v-text-field
                                class="custom-input-icon"
                                v-model="exchange_delivery_method"
                                placeholder="Select delivery method"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                color="primary"
                                @change="updateExchangeFee"
                                :menu-props="{ top: true, offsetY: true }"
                                attach
                                readonly
                            >
                            </v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="5" offset-md="2" xl="5" offset-xl="2">
                        <div class="form-group">
                            <label for="" class="d-flex align-center">
                                Character Name
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-on="on"
                                            v-bind="attrs"
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                            alt="info icon"
                                            style="margin-left: 7px"
                                        />
                                    </template>
                                    <span>Fill in the name of the WoW character that you want to receive the gold</span>
                                </v-tooltip>
                            </label>
                            <v-text-field
                                v-model="exchange_character_name"
                                outlined
                                height="36px"
                                dense
                                hide-details
                                :rules="[rules.required]"
                                color="primary"
                                attach
                                append-icon=""
                                placeholder="Character Name"
                                @change="emitInputDataExport"
                            >
                            </v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import regions from '@/assets/json/regions.json';
// import servers from '@/assets/json/exchangeServers.json';
import versions from '@/assets/json/versions.json';
import exchangeDeliveryMethods from '@/assets/json/exchangeDeliveryMethods.json';
import { getExchangeServers } from '../../api/services.js';

export default {
    props: ['exchangeAmount'],
    watch: {
        exchangeAmount: {
            deep: true,
            handler(val, oldVal) {
                this.exchange_amount = val;
                this.emitInputDataExport();
            },
        },
    },
    data: () => ({
        exchange_amount: null,
        exchange_delivery_method: 'Mail',
        exchange_delivery_methods: [],
        exchange_character_name: null,
        versions: versions,
        exchange_version: '',
        exchange_region: '',
        exchange_server: '',
        exchange_server_fee: 0,
        exchange_delivery_method_fee: 0,
        regions: regions,
        server: null,
        items: [],
        serversList: null,
        rules: {
            required: (value) => !!value || 'Is required!',
        },
    }),
    mounted: function () {
        getExchangeServers()
            .then((res) => {
                let data = res.data;
                console.log({ data });
                delete data['Lost Ark'];
                delete data['New World'];
                // console.log(
                //     {
                //         LA: data['Lost Ark'],
                //         NW: data['New World']
                //     }
                // )
                this.serversList = data;
            })
            .catch((err) => {
                console.log(err);
            });

        var deliveryMethods = exchangeDeliveryMethods;
        deliveryMethods = deliveryMethods.map((deliveryMethod) => {
            return deliveryMethod.name;
        });
        this.exchange_delivery_methods = deliveryMethods;
    },
    methods: {
        updateServerList() {
            if (this.exchange_version && this.exchange_region) {
                // var servers = this.serversList[this.exchange_version][this.exchange_region];
                // Update the server list based on the "SoM" exchange version and region
                if (this.exchange_version === 'Classic Era') {
                    var servers = this.serversList['SoM'][this.exchange_region];
                } else {
                    // Update the server list based on the selected exchange version and region
                    var servers = this.serversList[this.exchange_version][this.exchange_region];
                }
                var serverWithFactions = [];

                if (this.exchange_server != '') {
                    this.exchange_server = '';
                    this.exchange_server_fee = 0;
                }

                serverWithFactions.push(
                    servers.map((server) => {
                        var serverName = server.name;
                        var internalServerWithFactions = [];

                        server.faction.forEach((serverFaction) => {
                            if (this.exchange_version !== 'Lost Ark') {
                                internalServerWithFactions.push(`${serverName} - ${serverFaction.name}`);
                            } else {
                                internalServerWithFactions.push(`${serverName}`);
                            }
                        });

                        return internalServerWithFactions;
                    }),
                );

                serverWithFactions = serverWithFactions[0];
                this.items = serverWithFactions
                    .toString()
                    .split(',')
                    .sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    });
            }

            this.emitInputDataExport();
        },
        emitInputDataExport() {
            this.$emit('inputDataExport', {
                exchange_version: this.exchange_version,
                exchange_region: this.exchange_region,
                exchange_server: this.exchange_server,
                exchange_server_fee: this.exchange_server_fee,
                exchange_amount: this.exchange_amount,
                exchange_delivery_method: this.exchange_delivery_method,
                exchange_character_name: this.exchange_character_name,
                exchange_delivery_method_fee: this.exchange_delivery_method_fee,
            });
        },
        updateExchangeFee() {
            if (this.exchange_delivery_method) {
                var selectedMethod = exchangeDeliveryMethods.filter(function (element) {
                    return element.name === this;
                }, this.exchange_delivery_method);

                selectedMethod = [...selectedMethod].shift();

                if (selectedMethod) {
                    this.exchange_delivery_method_fee = selectedMethod.extra_fee;
                }
                this.emitInputDataExport();
            }
        },
        updateExchangeServerFee() {
            if (this.exchange_server.length > 0) {
                var serverFactionArray = this.exchange_server.split(' - ');

                var servers = this.serversList[this.exchange_version][this.exchange_region];
                servers = JSON.parse(JSON.stringify(servers));

                var selectedFaction = [];
                servers.forEach((server) => {
                    if (server.name == serverFactionArray[0]) {
                        selectedFaction = server.faction.filter((faction) => {
                            return faction.name == serverFactionArray[1];
                        });
                    }
                });

                this.exchange_server_fee = selectedFaction[0].fee;
            }
            this.emitInputDataExport();
        },
    },
};
</script>
<style>
.vat-msg {
    /* color: rgb(23, 208, 193) !important; */
    opacity: 0.8;
    position: absolute;
    display: flex;
}
.space {
    margin-top: 50px;
}
.user-exchange-container input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
.user-exchange-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.user-exchange-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.user-exchange-container .v-icon {
    color: #fff !important;
}
.user-exchange-container .disabled-input input::placeholder {
    color: #fff !important;
    text-align: center;
}
.user-exchange-container .v-input__icon {
    display: flex;
    height: 15px;
    flex: 1 0 auto;
    justify-content: flex-end;
    min-width: 24px;
    width: 165px;
    right: 0;
    position: absolute;
}
.user-exchange-container .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.user-exchange-container .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.user-exchange-container .v-menu__content .v-list {
    background: #555c70;
}
.user-exchange-container .theme--light.v-input input {
    color: #fff;
}
.user-exchange-container .v-menu__content::-webkit-scrollbar {
    width: 6px;
}
.user-exchange-container .v-menu__content::-webkit-scrollbar-track {
    background: #43495a;
}
.user-exchange-container .v-menu__content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
}
.v-dialog .modal.grey {
    background: #555c70;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
</style>
<style lang="scss" scoped>
.user-exchange-container {
    flex-basis: 1;

    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;

            .card-title {
                font-weight: 600;
            }

            .items-count span {
                color: #aaadb7;
            }
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;

            h2 {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: rgba(255, 255, 255, 0.5);
                text-align: center;
                margin-bottom: 0;
            }
            p {
                font-size: 16px;
                line-height: 22px;
                color: rgba(255, 255, 255, 0.8);
            }
            .mb-105 {
                margin-bottom: 105px;
            }
            .v-divider.theme--light {
                border-color: rgba(255, 255, 255, 0.1);
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 12px;
                    line-height: 11px;
                    color: rgba(255, 255, 255, 0.6);
                    border-radius: 4px;
                    margin-bottom: 7px;
                }
                &::deep input::placeholder {
                    font-size: 13px;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            .exchange-header {
                padding-top: 10px;
                text-align: center;
                line-height: 16px;
                color: #ffffff;
            }
        }
    }
}
</style>
