<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-container fluid fill-height id="faq">
            <h1>Frequently asked questions</h1>
            <p>
                If the FAQ does not answer your questions please contact Trade-Raid Support through
                <a target="_blank" href="https://www.facebook.com/TradeRaid" style="color: #17d0c1">Facebook</a> Live
                Chat or by Mail <br />
                We are ready to deliver your gold when you make your order but please note that Trade-raid is currently
                in BETA.
            </p>
            <h2 id="trade">Trade Service</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="ratios">Gold Ratios</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs3" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="exchange">Exchange Service</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in tgbs" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
            <h2 id="delivery">Delivery</h2>
            <section class="collapse-container">
                <v-expansion-panels flat>
                    <v-expansion-panel v-for="(faq, index) in faqs2" :key="index">
                        <v-expansion-panel-header expand-icon="mdi-chevron-down">{{
                            faq.title
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content class="flex-column">
                            <div v-html="faq.ans"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </section>
        </v-container>
    </v-lazy>
</template>
<script>
export default {
    name: 'FAQ',
    metaInfo: {
        title: 'FAQ',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Read more about Trade-Raid at our Frequently asked questions page. Learn to know how our services works, gold ratios and delivery speed.',
            },
        ],
    },
    methods: {
        expand(expand_type) {
            const hash = window.location.hash;
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const index = parseInt(params.q);
            if (hash === `#${expand_type}`) {
                return index;
            }
            return null;
        },
    },
    data: () => ({
        isActive: false,
        faqs: [
            {
                title: 'What is Trade-Raid.com?',
                ans: `
                    <p>Trade-Raid is a cross-game trading platform. We have since 2020 helped thousands of games to trade their Steam Items to WoW Gold and exchange their own Gold between Retail and Classic.</p>
                    <p>We offer two unique and innovative services:</p>
                    <p>Trade Service: Perform one-way trades with Steam Items (CSGO, DOTA2, RUST, TF2 & Steam Community Items) for WoW Retail/Classic Gold.</p>
                    <p>Exchange Service: Trade your own gold between Retail and Classic both ways.</p>
                `,
            },
            {
                title: 'How do I start trading my Steam Items for Gold?',
                ans: `
                    <p>The process is simple and straightforward - punctually:</p>
                    <ul>
                        <li><b style="bold; color:white">Sign in with Steam</b> in the Top Right corner and paste your <b style="bold; color:white">trade link</b></li>
                        <li>Pick the <b style="bold; color:white">WoW Version, Region and Server</b> you want your gold delivered to</li>
                        <li>On the left, <b style="bold; color:white">select the items</b> you would like to trade <b style="bold; color:white">from your inventory</b>. On the right, you can see <b style="bold; color:white">how much gold</b> you will receive for the items you have deposited.</li>
                        <li><b style="bold; color:white">Fill out</b> the remaining blankets (E-mail, Character Name and Gold Delivery Method)</li>
                        <li>Press the <b style="bold; color:white">TRADE</b> button and our trade-bot will <b style="bold; color:white">open a new window</b> with the offer for the items you have requested to trade for gold</li>
                        <li><b style="bold; color:white">Accept the offer on your PC</b> - Double check that the Steam Level and Registration Date of the Trade-Bot matches when you confirm on your Phone</li>
                        <li><b style="bold; color:white">Confirm the offer on your Phone</b> - Double check that the Steam Level and Registration Date of the Trade-Bot matches when you accepted the offer on your PC</li>
                        <li><b style="bold; color:white">Once we have successfully received your items</b> Trade-Raid will deliver the gold to your in-game character.</li>
                    </ul>
                `,
            },
            {
                title: 'Do I need to have Steam Mobile Authenticator to trade my Steam Items to MMO gold?',
                ans: `
                    <p>We strongly recommend all users of Trade-Raid to confirm their trade with Steam Mobile Authenticator.</p>
                    <p>It makes the trade instant, and it is safer.</p>
                    <p>Trade-Raid will first prepare the gold once your items have been received in our bots’ inventory.</p>
                    <p>In the case you have not set up Steam Mobile Authenticator we advise you to download the Steam App to your phone, login with your Steam Account and set up Steam Guard on the Steam app and wait the required 7 days from Steam to trade again.</p>
                `,
            },
            {
                title: 'I do not have Steam Mobile Authenticator, but I want to trade my items anyway, is this possible?',
                ans: `
                    <p>Yes, but beware that Steam will set the trade on hold for 15 days which means we will first receive your items after the trade hold.</p>
                    <p>At Trade-Raid this is not an issue. We deliver gold to users that waited the 15-day trade on hold daily. Approximately 25% of all our orders are successfully done through e-mail confirmations where the user waits the 15-day trade on hold period.</p>
                    <p>The gold will always be delivered after we have received your items. You can at any time cancel your trade during the on hold period if you change your mind on Steam.</p>
                `,
            },
            {
                title: 'I have Steam Balance or a Steam Gift Card, can I trade these for MMO Gold?',
                ans: `
                    <p>Trade-Raid has offered this since for years since its launch, but Steam has in 2024 made changes their community market which has led to this service can no longer be provided. </p>
                    <p>We recommend you use your Steam Wallet Funds/Gift Card to purchase CSGO skins and wait the 8 day trade-lock period and then use our Trade Service to get your gold. </p>
                `,
            },
            {
                title: 'I want to use your services, but I am a little skeptical, what can I do?',
                ans: `
                  <p>We encourage all users to do their own research about Trade-Raid.com such as visiting our Trustpilot and Social Media pages.</p>
                  <p>In addition to this, you can also:</p>
                  <ul>
                    <li>Do a small "test" trade within our low minimum trade amounts</li>
                    <li>
                    Get in touch with Trade-Raid at: 
                    <a style="color: #17d0c1;" href="mailto:admin@trade-raid.com" >admin@trade-raid.com</a>
                    or
                    <a style="color: #17d0c1;" href="https://steamcommunity.com/id/wowgoldtrader/" target="_blank">Steam</a></li>
                  </ul>
                `,
            },
            {
                title: 'My initial trade offer was declined, and I have received a new offer, what do I do?',
                ans: `
                    <p>Always double check the Trade-bot's Steam Level and Registration Date both when you accept the trade offer and when you confirm the offer on your phone. They must match. If not, please decline your trade immediately without hesitation.</p>
                    <p>If the first trade offer you created was declined automatically, and you receive a new offer that you have not created (a second trade offer) please also decline that trade immediately without hesitation. You may be API scammed.</p>
                    <p>Please read more about API scams here: https://dmarket.com/blog/steam-api-key-scam/</p>
                    <p>For Danish Users: https://scammed.dk/api-scam/</p>
                    <p>You can always reach out for Trade-Raid <a target="_blank" href="https://trade-raid.com/support" style="color:white">support</a> if you need further assistance.</p>
                `,
            },
            {
                title: 'Is it safe to use your services?',
                ans: `
                    <p>At Trade-Raid.com transparency with our users is essential as our services place us in a grey zone.</p>
                    <p>To be clear: Exchanging gold for other currencies outside the game's universes is against the games rules. Trade at your own risk. Though at Trade-Raid.com, we do our best to reduce this risk as much as possible.</p>
                    <br>
                    <p>Here is how:</p>
                    <ul>
                        <li>We collaborate with the same boosting communities and partners that we have known for years (since 2015)</li>
                         <li>Trade-Raid has a zero-tolerance policy on the usage of bots and other exploits</li>
                         <li>We supplement our gold delivery with gold from our own users that deposited their gold at our Exchange Service.</li>
                    </ul>
                       <p>Trade-Raid's statistics are:</p>
                    <ul>
                        <li>More than 6500 total orders handled (since October 2020 to January 2025)</li>
                        <li>8 user-reported temporary bans & gold stripped</li>
                        <li>Sanction percentage 0,0012%</li>
                        <li>Although unreported cases may exist, using Trade-Raid's services is low risk. We have no records of permanent bans. This includes for all our users, partners and our own gold stocks.</li>
                    </ul>
                    <p>If it happens that a user is sanctioned after using our services, please reach out to Trade-Raid Support.</p>
                `,
            },
            {
                title: 'Do you offer refunds?',
                ans: `
                    <p>All trades at our Trade Service are final. Trade-Raid do not offer refunds in general due to the nature of the exchange between games and the practical challenges that are involved in such transactions. Most Steam Items are locked for 8 days after they have been traded and it is common practice that in-game gold is never returned once the gold has been delivered.</p>
                    <p>Refunds are possible in the rare case that the user has not received their gold yet or if an unexpected error has occurred.</p>
                    </p>Due to fluctuation in gold economies in certain periods, we may refund your gold deposit at the Exchange Service. You will receive a mail from us if that is the case and the gold ratios will be corrected immediately.</p>
                    `,
            },
        ],
        tgbs: [
            {
                title: 'What is the Exchange Service?',
                ans: `
                    <p>The Exchange Service allows players to trade their own WoW Gold across servers and WoW versions both ways.</p>
                    `,
            },
            {
                title: 'How does Exchange Service work?',
                ans: `
                    <p>The process is very simple – punctually:</p>
                    <ul>
                        <li>Sign In/Sign Up in the top right corner with your E-mail</li>
                        <li>On the left side chose which Version, Region and Server you want to deposit gold from your character to Trade-Raid’s Gold recipient.</li>
                        <li>Mention the Character Name that is going to mail the gold in-game</li>
                        <li>On the right side pick your desired WoW Version, Region and Server you want your gold Exchanged to.</li>
                        <li>Mention the Character Name that is going to receive the Exchanged Gold Amount from Trade-Raid.</li>
                        <li>Fill out your e-mail and press the TRADE Button</li>
                        <li>You will afterwards be directed to our order request page with all the details that you should double-check. Read the name of Trade-Raid’s Gold Recipient on the chosen Server and Faction.</li>
                        <li>Once ready, press the ‘’Ready to Trade Button’’.</li>
                        <li>In-game mail the Deposit Gold Amount to Trade-Raid’s Gold Recipient.</li>
                        <li>Press ‘’I have deposited my gold’’ and let Trade-Raid handle the rest.</li>
                    </ul>
                `,
            },
            {
                title: 'What are the Exchange Rates?',
                ans: `
                    <p>Trade-Raid’s gold ratio is 1:1.</p>
                    <p>When it comes to trading across different game versions our goal is to maintain a 1:1 ratio as much as possible though fluctuations may occur.</p>
                    <p>The base fee for any exchange is 20%. The “Exchanged Amount” is always the total Gold Amount you will receive in-game.</p>
                    <p>For more information about gold ratios, visit our WoW Gold Tendencies document here which is frequently updated.</p>
                    `,
            },
        ],
        faqs2: [
            {
                title: 'How long does it take before I receive my gold?',
                ans: `
                   <p>At Trade-Raid we always try to deliver your gold as soon as possible. Our average delivery time is 12 hours while our maximum delivery time is up to 5 days.</p>
                   <p>Exchange Service orders do on average take longer to complete because Trade-Raid has to await and confirm the user's in-game deposit before the order can be completed. </p>
                   <p>Exchange Service orders still follow the same delivery expectations as Trade Service orders once the gold deposit has been confirmed.</p>

                   <p>You can at any time reach out to Trade-Raid support via live chat, 
                    <a style="bold; color:#17d0c1" href="mailto:admin@trade-raid.com">mail</a> or <a style="bold; color:#17d0c1" href="https://steamcommunity.com/id/wowgoldtrader/" target="_blank">Steam</a> for questions about your order. </p>
                    <p>If you want your gold as soon as possible, we recommend you to get in touch with us on <a style="bold; color:#17d0c1" href="https://steamcommunity.com/id/wowgoldtrader/" target="_blank">Steam</a> before placing your order.</p>
                    `,
            },
            {
                title: 'Which in-game gold delivery methods do Trade-Raid offer?',
                ans: `
                    <p>At Trade-Raid the in-game gold delivery methods we offer to our users are: 1) Mail and 2) Auction House.</p>
                    <p>In-game mail is the most popular type of gold delivery.</p>
                    Mail:
                    <ul>
                        <li>If the user chooses to receive their gold by Mail, Trade-Raid will deliver the gold straight to your character's in-game mailbox.</li>
                        <li>For larger orders it is common that the gold received may be split into smaller mails that the user will receive on different timestamps throughout the day.</li>
                        <li>Please note that WoW delays all in-game mails by 1 hour.</li>
                    </ul>
                    Auction House:
                    <ul>
                       <li>If the user chooses to receive their gold by the in-game Auction House, Trade-Raid will purchase the Battle Pet/BoE Item the user wrote on the Trade-Page that will be listed by the user on the Auction House.</li>
                       <li>At Trade-Raid it is our impression that the chosen gold delivery method is completely determined by the users preference.</li>
                       <li>Note that the in-game Auction House takes a 5% cut which means that the user always receives more gold when they choose the Mail delivery method.</li>
                       <li>It is estimated that 80% of all Trade-Raid's orders are delivered by mail and 20% are delivered through Auction House.</li>
                    </ul>
                `,
            },
            {
                title: 'I have filled out incorrect WoW Information/Details but I have confirmed my trade! What do I do?',
                ans: `
                    <p>Please reach out to Trade-Raid <a href="https://trade-raid.com/support" style="color:white">Support</a> immediately to assist you.</p>
                    `,
            },
        ],
        faqs3: [
            {
                title: 'Which economies and price sources do Trade-Raid base its gold ratios on?',
                ans: `
                    <p>Trade-Raid bases its gold ratios on WoW Token and competitive gold market sites. We do this to ensure that we can offer the highest amount of gold to our users.</p>
                    <p>At Steam, Trade-Raid retrieves the average sale tendency over the last 14 days of your Steam Items from the Steam Community Market. To supplement this, we also retrieve data from real life third-party markets for CSGO and DOTA2.</p>
                    <p>For Rust, TF2 and Steam Community Items our gold ratios are solely based on the Steam Community market.</p>
                    <p>The Trade Service's gold ratios are updated automatically on a daily basis.</p>
                `,
            },
            {
                title: 'I have an item that might be bugged with an absurd high value at the Trade Service, should I trade my item?',
                ans: `
                    <p>Trade-Raid retrieves the average sale tendency over the last 14 days of your Steam Items from the Steam Community Market. This means your gold offers may fluctuate a bit. </p>
                    <p>Trade-Raid is in BETA and utilizes a simplified ‘’one-size fits all’’ method to retrieve the data for of Steam Items to MMO gold. Most of our trades are accurate and properly calculated but we have had rare cases of inaccurate calculations.</p>
                    <p>Currently, CSGO StatTrak Knives, Rare TF2 and Steam Community Items often have an unstable or low sale tendency that tend to be valuated too high and thus inaccurately by our gold calculator. Please note that if a User trades such items Trade-Raid may have to reduce the gold amount or in rare cases cancel your trade.</p>
                    <p>Thus, we advise you not to trade items that has an absurd high value. Instead, reach out for Trade-Raid support first and see if we can help you.</p>
                    <p>If you find an item that is bugged and has an extreme high price and reach out to us with a screenshot of the item at our Trade-Page and the Item Name. Trade-Raid support will reward you with extra free gold on your next order.</p>
                `,
            },
            {
                title: 'My item is going for (e.g 20€) on the Steam Market should I not receive the gold equivalency of 1 WoW Token?',
                ans: `
                    <p>Your Steam Value is locked within the Steam platform and cannot be withdrawn to your bank account as well as you cannot pass your Steam Items to Blizzard and exchange it for a WoW Token.</p>
                    <p>In other words: Your Item is worth 20€ on the Steam Market but not in real life currency markets.</p>
                    <p>It is also important to state that ALL Steam Items have 15% added value that are pure Sale Fees that goes to Steam if you sell the item on the Steam Market.</p>
                    <p>A common rule of thumb is to pay 45-65% of Steam Market value if someone were to buy a CSGO Skin for real life money. The percentage is substantially lower for less popular games such as TF2 and Rust and for low tier/trash items.</p>
                `,
            },
            {
                title: 'Why do some games have better gold ratios than other games?',
                ans: `
                    <p>This is solely due to the liquidity and popularity of those games and their skin economy. Turning Rust Items into real life money is a more expensive process and requires more knowledge, time and effort compared to DOTA2 high tier items.</p>
                    <p>At Trade-Raid, we have two metrics that increases the gold ratio of your items:</p>
                    <p>1) The popularity of the Steam Game you are trading items from (Where CSGO has the highest and Steam Community Items have the lowest ratios)</p>
                    <p>2) The value of the specific items in regards of their item ''tiers'' (only for CSGO and DOTA2)</p>
                `,
            },
        ],
    }),
};
</script>

<style>
#faq .v-expansion-panel-content p {
    margin-bottom: 0;
}
</style>

<style lang="scss" scoped>
#faq {
    padding-top: calc(80px + 100px);
    padding-bottom: 124px;
    flex-direction: column;
    align-content: center;
    background: linear-gradient(
        90deg,
        rgba(0, 14, 25, 0.1) 0%,
        rgba(15, 24, 45, 0.1) 30.7%,
        rgba(62, 0, 108, 0.1) 60.29%,
        rgba(11, 72, 67, 0.1) 79.69%,
        rgba(15, 24, 45, 0.1) 95.48%
    );
    h1 {
        width: 100%;
        font-weight: 600;
        font-size: 36px;
        line-height: 49px;
        color: #fff;
        text-align: center;
    }
    h2 {
        width: 100%;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        color: #fff;
        text-align: center;
    }
    .spec-list {
        list-style: circle;
    }
    .fat {
        font-weight: bold;
        color: #fff;
    }
    p {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 70px;
        a {
            color: #fff;
        }
    }
    .collapse-container {
        width: 100%;
        max-width: 1170px;
        .v-expansion-panel {
            border-radius: 2px;
            background: #383f51;
            margin-top: 0;
            margin-bottom: 20px;
        }
        .v-expansion-panel-header {
            padding: 25px 30px;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            &::v-deep .v-expansion-panel-header__icon i {
                color: #fff;
            }
            &.v-expansion-panel-header--active,
            &.v-expansion-panel-header--active::v-deep .v-expansion-panel-header__icon i {
                color: #17d0c1;
            }
        }
        .v-expansion-panel-content {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 1);
            div {
                padding-top: 0;
                padding-bottom: 25px;
            }
        }
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
            padding: 0;
            font-size: 16px;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}
</style>
