<template>
    <v-col @change="setSteamInfo()" class="user-items" cols="12">
        <v-tabs v-model="tab" hide-slider :show-arrows="false">
            <v-tab @click="getItems(tab.value)" v-for="tab in tabList" :key="tab.value" :href="`#${tab.value}`">
                <span class="tab-title-container">
                    <img class="steam-game-icon" :src="`/images/steam-games/${tab.title.toLowerCase()}.png`" />
                    {{ tab.title }}</span
                >
            </v-tab>
        </v-tabs>
        <div v-if="!invetoryIsPrivate" class="panel-container d-flex justify-space-between">
            <div class="d-flex1">
                <v-tooltip top color="black" show="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn depressed class="select-btn" @click="selectAll" v-bind="attrs" v-on="on"
                            >select all</v-btn
                        >
                    </template>
                    <div class="sup">
                        Select All Items in the chosen game category. <br />
                        Get +10% Gold Bonus in each game when selecting all items (Minimum 10 items)
                    </div>
                </v-tooltip>

                <v-btn depressed text icon @click="updateItems"
                    ><img src="https://trade-raid-com.mo.cloudinary.net/update.svg" alt="update icon" />
                </v-btn>
            </div>
            <div class="d-flex" style="gap: 10px">
                <v-text-field
                    class="search-btn"
                    v-model="search"
                    hide-details
                    placeholder="Search skin"
                    prepend-inner-icon="mdi-magnify"
                    style="width: 50%; height: auto"
                    outlined
                    dense
                    @input="searchList"
                    :clearable="search && search.length > 2"
                    @click:clear="clear"
                ></v-text-field>

                <v-select
                    class="sort-btn"
                    v-model="sort"
                    :items="['High to low', 'Low to high']"
                    placeholder="Order by"
                    outlined
                    style="width: 50%; height: auto"
                    dense
                    hide-details
                    :append-icon="'mdi-chevron-down'"
                    :rules="[rules.required]"
                    color="primary"
                    :menu-props="{ offsetY: true }"
                    attach
                    @change="sortList"
                ></v-select>
            </div>
        </div>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in tabList" :key="tab.value" :value="tab.value">
                <v-card outlined>
                    <div v-if="invetoryIsPrivate && $store.state.auth.user._id" class="pa-4 white--text primary">
                        <div class="trade-info">
                            <h3>There was an error retrieving the data of your Steam Items.</h3>
                            <ul>
                                <li>
                                    This can be due to different reasons. Here are the most common solutions from top to
                                    bottom:
                                </li>
                                <li>
                                    1) Your Steam Profile and/or Steam Inventory is set to
                                    <bold class="fat">Friend's Only or Private</bold>. Both
                                    <bold class="fat">must be set to Public</bold> to be calculated. You can access your
                                    Inventory Privacy Settings directly
                                    <a
                                        class="steam-settings"
                                        :href="`https://steamcommunity.com/id/me/edit/settings/`"
                                        target="_blank"
                                        >here.</a
                                    >
                                    Once you have made your Steam Profile/Inventory Public please Refresh the Trade
                                    Service Page on your browser.
                                </li>
                                <li>
                                    2) You <bold class="fat">do not have any items in the specific game</bold> you are
                                    trying to scan items from. If
                                    <bold class="fat">you can see the gold prices for your skins from other games</bold
                                    >, simply <bold class="fat">ignore this error message</bold>.
                                </li>
                                <li>
                                    Once you have made your Steam Inventory Public please Refresh the Trade Page on your
                                    browser.
                                </li>
                                <li>
                                    3) Stability issues. Trade Service is in
                                    <bold class="fat">current development (BETA) and can be unstable at times</bold>.
                                    Users with large Steam Inventories may
                                    <bold class="fat">experience lag and bugs when scanning</bold> their items. Try to
                                    refresh the Trade Page and <bold class="fat">patiently</bold> attempt to trade your
                                    items again.
                                </li>
                                <li>
                                    If you are still facing issues, please contact Trade-Raid Support through our Live
                                    Chat at the bottom right corner.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <v-card-text>
                        <v-row class="d-flex align-center justify-center" v-if="!$store.state.auth.user._id">
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <div class="trade-info steam-unsigned">
                                    <h3>
                                        You are currently viewing Trade-Raid's Trade Page without being Signed in
                                        Through Steam
                                    </h3>
                                    <ul>
                                        <li>
                                            <p>
                                                To find the MMO gold valuation of your Steam Items on your chosen Server
                                                and Realm please Sign in with Steam
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-stretch" v-else>
                            <v-col cols="12" v-if="noItems" class="d-flex justify-center align-center">
                                <h2 style="color: #fff; font-size: 25px">No Items</h2>
                            </v-col>
                        </v-row>
                        <!-- <div v-if="goldIncreasesWithDynamicRatio()" class="choose-server">
                            Please choose a server.
                        </div> -->
                        <v-row
                            v-if="
                                (!inventoryList.length && !invetoryIsPrivate && $store.state.auth.user._id) ||
                                (loader && $store.state.auth.user._id)
                            "
                            ref="container"
                            class="d-flex align-stretch"
                        >
                            <v-col cols="12" class="d-flex justify-center align-center">
                                <Loader class="justify-center align-center"></Loader>
                                <h3 style="color: #fff; font-size: 10px; margin-top: 150px">
                                    <span class="center-small-text"
                                        >Please wait while we scan your items. Larger inventories have a longer loading
                                        time.</span
                                    >
                                    <br />
                                    <span class="center-small-text">
                                        If no items appear here, it means you have no Marketable & Tradable Steam items.
                                        Try choosing another game.
                                    </span>
                                </h3>
                            </v-col>
                        </v-row>
                        <v-row v-else ref="container" class="d-flex align-stretch">
                            <!-- <user-item v-if="gameHasShopDisplay && itemsDisplay" :item="displayShopItem"></user-item> -->
                            <user-item
                                :disabledItems="disableInventoryItems"
                                :items="
                                    inventoryList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                "
                                v-for="(item, index) in inventoryList.slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage,
                                )"
                                :key="index"
                                :item="item"
                            />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <div class="pages" v-if="totalPages > 0">
            <div class="page-control" @click="decrementPage()"><ChevronLeftIcon /></div>
            <div
                :class="{ activePage: currentPage === page }"
                v-for="(page, index) in pages"
                :key="index"
                @click="currentPage = page"
                class="page"
            >
                {{ page }}
            </div>
            <div class="page-control" @click="incrementPage()"><ChevronRightIcon /></div>
        </div>
        <div class="not-signed-in" v-if="!$store.state.auth.user._id">
            <div class="inner">
                <v-btn
                    class="btn-outline-primary rounded steam-btn"
                    style="color: #17d0c1; background-color: transparent"
                    @click="signIn"
                    height="45px"
                >
                    SIGN IN WITH STEAM
                </v-btn>
            </div>
            <div class="inner2">
                <trustpilot-mini></trustpilot-mini>
            </div>
        </div>
        <shopNavModal :showModal="showNavModal" />
    </v-col>
</template>

<script>
import UserItem from '../UserItem/';
import { mapGetters } from 'vuex';
import Loader from '../UI/loader.vue';
import SignIn from '../../components/SignIn';
import Mini from '../UI/trustpilot/mini.vue';
import shopNavModal from '../UI/modals/shopNavModal.vue';
import { getSteamItemServiceDisplays } from '../../api/services';
export default {
    components: {
        UserItem,
        Loader,
        SignIn,
        Mini,
        shopNavModal,
    },
    computed: {
        ...mapGetters(['items', 'offerItems', 'receive', 'gameData', 'selectedGame', 'showLoader', 'setSteamInfo']),
        loader() {
            return this.loading || this.showLoader;
        },
        inventory() {
            return this.items(this.tab);
        },
        invetoryIsPrivate() {
            return this.inventory && this.inventory.code === 'get-game-items-exception';
        },
        noItems() {
            return this.inventory && this.inventory.code === 'no-items';
        },
        disableInventoryItems() {
            let enableFilters = this.gameData.wowVersion === null && this.gameData.region === null;
            // this has been removed by @PJ because it makes absolutely no sense to pass this as a prop to UserItem, as it blocks all other items from trades if 1 single item in the inventory does not have a price
            // let itemsNotHavePrices = false;
            // for (const argumentsKey in this.inventoryList) {
            //     if (this.inventoryList[argumentsKey].steam_price === -1) {
            //         itemsNotHavePrices = true;
            //         break;
            //     }
            // }
            return enableFilters;
        },
        showNavModal() {
            return this.$store.state.shop.showShopNavModal;
        },
        gameHasShopDisplay() {
            const current_game = this.$store.state.trade.wow_version;
            if (!this.itemsWithDisplay.includes(current_game)) {
                return false;
            }
            return true;
        },
        displayShopItem() {
            let game = this.$store.state.trade.wow_version;
            if (!game) {
                game = 'the war within';
            }
            const f = this.itemsDisplay.filter((item) => {
                const alias = item.alias.includes(game.toLowerCase());
                if (alias) {
                    return item;
                }
            });
            if (f.length) {
                return f[0];
            }
            return null;
        },
    },
    data: () => ({
        itemsDisplay: null,
        itemDisplay: null,
        itemsWithDisplay: ['The War Within', 'WOTLK Classic', 'Classic Anniversary', 'Lost Ark', 'Final Fantasy XIV'],
        loading: true,
        inventoryList: [],
        tab: 'steam',
        currentPage: 1,
        itemsPerPage: 12,
        totalPages: 0,
        pages: [],
        displayedItems: [],
        tabList: [
            { value: 'steam', title: 'STEAM' },
            { value: 'dota', title: 'DOTA2' },
            { value: 'cs', title: 'CS2' },
            { value: 'rust', title: 'RUST' },
            { value: 'tf', title: 'TF2' },
        ],
        search: '',
        rules: {
            required: (value) => !!value || 'Is required!',
        },
        sort: 'High to low',
        lowerBoundPrice: 0,
    }),
    methods: {
        incrementPage() {
            if (this.currentPage >= this.totalPages) {
                return;
            }
            this.currentPage += 1;
        },
        decrementPage() {
            if (this.currentPage <= 1) {
                return;
            }
            this.currentPage -= 1;
        },
        goldIncreasesWithDynamicRatio() {
            const gamesWithDynamicRatiosAndServer = ['The War Within', 'WOTLK Classic'];
            if (
                (gamesWithDynamicRatiosAndServer.includes(this.$store.state.trade.wow_version) &&
                    !this.$store.state.trade.server &&
                    this.$store.state.auth.user.steam_id) ||
                (this.$store.state.trade.wow_version === null &&
                    this.$store.state.trade.server === null &&
                    this.$store.state.auth.user.steam_id)
            ) {
                return true;
            }
            return false;
        },
        goToFaq() {
            this.$router.push('/faq#ratios');
        },
        getItems(value) {
            this.tab = value;
            this.$store.dispatch('setGame', value);
            this.$store.dispatch('getItems', value);
        },
        selectAll() {
            let items = this.getSortedAvailableItems(this.inventoryList);
            if (items.length > 10) {
                this.$store.commit('SET_BOCKET_BONUS', { game: this.tab, game_state: true });
            }
            if (items.length > 0) {
                this.$store.dispatch('addSelectedItemToOffer', items).then(() => {
                    this.forceInitialSort();
                });
            }
        },
        updateItems() {
            this.loading = true;
            this.$store.dispatch('getItems', this.selectedGame).then(() => {
                this.search = '';
                this.loading = false;
                // this.$store.dispatch('clearOfferItems')
            });
        },
        searchList(val) {
            let items = this.inventoryList;
            if (this.search && this.search.length > 2) {
                items = items.filter((el) => {
                    let name = el.name;
                    name = name.toLowerCase();
                    return name.includes(this.search.toLowerCase());
                });
            } else {
                let inv = this.inventory.items;
                let sortedAvailableItems = this.getSortedAvailableItems(inv);
                let sortedNotTraidableItems = this.getSortedNotTraidableItems(inv);
                let sortedLowPriceItems = this.getSortedLowPriceItems(inv);
                let unavailableItems = this.getUnavailableItems(inv);
                items = sortedAvailableItems.concat(sortedNotTraidableItems, sortedLowPriceItems, unavailableItems);
            }
            this.inventoryList = items;
        },
        async signIn() {
            this.$store.dispatch('signIn');
        },
        clear() {
            this.search = '';
            this.sort = 'High to low';
        },
        sortList() {
            this.inventoryList.reverse();
        },
        getSortedAvailableItems(inventory) {
            let items = inventory.filter((item) => {
                return (
                    item.steam_price !== null &&
                    item.steam_price > 0 &&
                    item.price >= this.lowerBoundPrice &&
                    item.tradable
                );
            });
            return items.sort((a, b) => a.steam_price - b.steam_price).reverse();
        },
        getSortedNotTraidableItems(inventory) {
            let items = inventory.filter((item) => {
                return (
                    !item.tradable &&
                    item.price >= this.lowerBoundPrice &&
                    item.steam_price > 0 &&
                    item.steam_price !== null
                );
            });
            return items.sort((a, b) => a.steam_price - b.steam_price).reverse();
        },
        getSortedLowPriceItems(inventory) {
            let items = inventory.filter((item) => {
                return item.steam_price !== null && item.price < this.lowerBoundPrice;
            });
            return items.sort((a, b) => a - b).reverse();
        },
        getUnavailableItems(inventory) {
            let items = inventory.filter((item) => {
                return item.steam_price === null;
            });
            return items;
        },
        getShopServiceDisplays() {
            getSteamItemServiceDisplays()
                .then((res) => {
                    if (res.data.success) {
                        this.itemsDisplay = res.data.data;
                    }
                })
                .catch((err) => {
                    //
                });
        },
        forceInitialSort() {
            // reset pagination when tab changes
            this.currentPage = 1;
            this.pages = [];
            this.totalPages = 0;
            if (this.inventory) {
                // pagination
                if (this.inventory.items.length >= this.itemsPerPage) {
                    const pageNumber = Math.ceil(this.inventory.items.length / this.itemsPerPage);
                    this.totalPages = pageNumber;
                    for (let i = 1; i < pageNumber; i++) {
                        this.pages.push(i);
                    }
                    this.pages.push(this.pages.length + 1);
                }

                this.sort = 'High to low';
                let inv = this.inventory.items;
                let sortedAvailableItems = this.getSortedAvailableItems(inv);
                let sortedNotTraidableItems = this.getSortedNotTraidableItems(inv);
                let sortedLowPriceItems = this.getSortedLowPriceItems(inv);
                let unavailableItems = this.getUnavailableItems(inv);
                if (inv.length > 0 && inv[0].steam_price !== -1) {
                    this.inventoryList = sortedAvailableItems.concat(
                        sortedNotTraidableItems,
                        sortedLowPriceItems,
                        unavailableItems,
                    );
                } else {
                    this.inventoryList = inv;
                }
                this.loading = false;
            } else {
                this.inventoryList = [];
                this.loading = true;
            }
        },
    },
    watch: {
        inventory: {
            handler: function () {
                this.forceInitialSort();
            },
            deep: true,
        },
    },
    mounted() {
        this.lowerBoundPrice = this.$store.state.trade.lowerBoundPrice;
        setTimeout(() => {
            this.$store.dispatch('setGame', 'steam');
            this.$store.dispatch('getItems', 'steam');
        }, 1000);

        this.getShopServiceDisplays();

        this.$store.subscribe((mutation, state) => {
            switch (mutation.type) {
                case 'SET_USER':
                    this.$store.dispatch('setGame', 'steam');
                    this.$store.dispatch('getItems', 'steam');
                    break;
            }
        });
    },
};
</script>

<style>
.tab-title-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 15px;
}

.steam-game-icon {
    width: 20%;
}

.page-control {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.pages {
    display: flex;
    gap: 6px;
    color: #f1f1f1;
    padding-block: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.page {
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    background-color: #555c70;
    padding-block: 4px;
    padding-inline: 6px;
    border-radius: 5px;
}
.page:hover {
    opacity: 0.9;
}
.activePage {
    background-color: #17d0c1 !important;
}
.choose-server {
    /* color: #f1f1f1; */
    color: rgb(255, 255, 255);
    background-color: rgba(81, 89, 187, 0.1);
    border-color: rgb(87, 97, 255);
    padding-block: 10px;
    font-size: 1rem !important;
    display: flex;
    justify-content: center;
}
.not-signed-in {
    background-color: #383f51 !important;
    display: flex;
    flex-wrap: wrap;
}
.not-signed-in > div.inner {
    margin: 10px;
    padding: 20px;
    font-size: 30px;
}
@media screen and (min-width: 1200px) {
    .not-signed-in > div.inner2 {
        max-width: 170px;
        height: 100%;
    }
}
@media screen and (max-width: 1200px) {
    .steam-btn {
        padding: 20px;
    }
    .not-signed-in > div.inner2 {
        margin-top: 20px;
    }
}
.btn-outline-primary {
    border: 1px solid #17d0c1;
    color: #17d0c1;
    background-color: #17d0c1;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #17d0c1;
    border-color: #17d0c1;
    color: #17d0c1 !important;
    -webkit-box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
    box-shadow: 0 3px 5px 0 rgba(47, 85, 212, 0.3);
}
.fat {
    font-weight: bold;
}
div > .trade-info > h3 {
    color: #f1f1f1;
}
.center-small-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
div > .unsigned {
    background-color: #555c70 !important;
}
div > .trade-info > ul {
    list-style-type: none;
}
.steam-settings {
    color: #17d0c1 !important;
    text-decoration: underline;
}
.steam-settings:hover {
    text-decoration: underline;
    opacity: 0.7;
}
div > .trade-info > li > a {
    color: #f1f1f1;
}
div > .trade-info > li > a:hover {
    color: #17d0c1;
}

div > .trade-info > ul > li > p {
    color: #f1f1f1;
    text-decoration: none;
}
div > .trade-raid > ul {
    list-style-type: none;
}
.user-items input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
.user-items
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.user-items
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.user-items .v-icon {
    color: #fff !important;
}
.user-items .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.user-items .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.user-items .v-menu__content .v-list {
    background: #555c70;
}
.user-items .theme--light.v-input input {
    color: #fff;
}
.v-tabs .v-item-group {
    background: transparent !important;
}
.v-tabs .v-slide-group__prev,
.v-tabs .v-slide-group__next {
    display: none !important;
}
.v-slide-group__content {
    justify-content: space-between;
}
.user-items input::placeholder {
    color: rgba(255, 255, 255, 0.3) !important;
}
.user-items fieldset {
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
div div.user-items .v-icon {
    color: rgba(255, 255, 255, 0.3) !important;
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
    .d-flex1 {
        display: block;
        margin-left: auto;
        margin-right: auto;

        width: 100%;
        height: auto;
    }
    .select-btn {
        background-color: #17d0c1 !important;
        border-radius: 3px;
        height: 36px;
        font-weight: bold;
        font-size: 8px !important;
        line-height: 18px;
        text-transform: uppercase;
        color: #ffffff;
        padding: 9px 22px;
    }
    .d-flex {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
        .sort-btn {
            width: 40%;
        }
        .v-select {
            width: 60%;
        }
        .search-btn {
            display: none !important;
        }
    }
}
@media screen and (min-width: 1200px) {
    .d-flex1 {
        display: -ms-flexbox !important;
        display: flex !important;
        right: -100px;
        width: 50%;
        height: auto;
    }
}
.row {
    height: 100%;
}
.user-items {
    flex: unset !important;
    .v-tabs {
        flex: unset;
        .v-tab {
            background: #394154;
            border-radius: 5px 5px 0 0;
            width: calc(100% / 5 - 40px / 5);
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            &.v-tab--active {
                background: #555c70;
            }
            span {
                color: #ffffff !important;
            }
        }
        &::deep .v-slide-group__prev,
        &::deep .v-slide-group__next {
            display: none;
        }
        .v-tab:not(:last-child) {
            margin-right: 10px;
        }
    }
    .panel-container {
        padding: 12px 20px;
        background: #555c70;
        .v-input {
            width: 155px;
            flex: unset;
        }
        .select-btn {
            background-color: #17d0c1 !important;
            border-radius: 3px;
            height: 36px;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            color: #ffffff;
            padding: 9px 22px;
            @media screen and (min-width: 1200px) {
                width: 35%;
                height: 40px;
            }
        }
        .gift-btn {
            background-color: #383f51;
            border-radius: 10px;
            height: 36px;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            color: #ffffff;
            padding: 9px 10px;
            transition-duration: 0.5s;
            cursor: pointer;
            @media screen and (min-width: 1200px) {
                width: 50%;
                height: 40px;
            }
        }
        .gift-btn:hover {
            background-color: #555c70;
            color: white;
        }
    }
    .v-tabs-items {
        background-color: transparent !important;
    }
    .v-card {
        height: 100%;
        background: #383f51;
        border-radius: 0;
        border: 0;
        div {
            p {
                font-size: 16px;
            }
        }
        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            .card-title {
                font-weight: 600;
            }
            .items-count span {
                color: #aaadb7;
            }
        }
        .v-card__text {
            padding: 18px 20px;
            min-height: 185px;
            max-height: 470px;
            overflow: auto;
        }
        .inventory-private-steps {
            li {
                p {
                    font-size: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>

<style>
.profile-main-loader {
    left: 50% !important;
    margin-left: -100px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -100px;
    width: 45px;
    z-index: 9000 !important;
}
.profile-main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: 200px;
    height: 200px;
}
.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}
.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 50px;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 50px;
    margin: auto;
}
.loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}
@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@-webkit-keyframes color {
    0% {
        stroke: #fff;
    }
    40% {
        stroke: #fff;
    }
    66% {
        stroke: #fff;
    }
    80%,
    90% {
        stroke: #fff;
    }
}
@keyframes color {
    0% {
        stroke: #fff;
    }
    40% {
        stroke: #fff;
    }
    66% {
        stroke: #fff;
    }
    80%,
    90% {
        stroke: #fff;
    }
}
</style>
