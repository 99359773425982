<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <div class="wrapper">
            <!-- <div class="space"></div>
            <div class="space"></div>
            <center>
                <h1 data-v-1ca70176="" class="heading mb-3" style="font-weight: bold; font-size: 45px">Giveaways</h1>
            </center>
            <p data-v-1ca70176="" class="para-desc mx-auto text-muted" style="text-align: center; color: white">
                Participate in awesome giveaways provided by Trade-Raid! Win tons of MMO Gold, In-game mounts, CSGO
                Skins and Game Codes.
                <BR /><BR />🎉 We are currently holding a huge Gold Giveaway here on our site! Join below 🎉
                <BR /> Check out our <a href="https://www.instagram.com/p/CofSBrSMLYF/" target="_blank" style="color:#17d0c1">Instagram page here</a> to participate. -->
            <!-- <BR />We currently have no active giveaways. Check out our Rewards below for other ways to earn free
                loot and gold! -->
            <!-- </p> -->
            <!-- Naar vi har giveaways: -->
            <!-- <section style="width: 100%; overflow: hidden">
                <div class="space"></div>
                <gleam-comp1></gleam-comp1>
            </section> -->
            <!-- More than 1 gleam comp, side by side  -->
            <!-- <gleam-comp1 class="gleam1"></gleam-comp1>
            <gleam-comp2 class="gleam2"></gleam-comp2>
            <div class="space"></div>
            <div class="space"></div> 
            </section>-->
            <!-- <div class="space"></div>
            <div class="space"></div> -->
            <section>
                <div class="space"></div>
                <div class="post">
                    <div class="post-imveteran">
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfXMedFXSQ-_BV3osgoNM7StUnSoy9EotFVzXcrQmGCTX3rkg/viewform?usp=sf_link"
                            target="_blank"
                        >
                            <img
                                class="epic_rewards"
                                src="https://trade-raid-com.mo.cloudinary.net/rewards_survey.png"
                                alt="trade-raid-feedback"
                            />
                        </a>
                    </div>
                    <h2>Feedback Questionnaire</h2>
                    Answer our short Feedback Survey and receive a small pouch of WoW Gold. All you need to do is share
                    your feedback with us!
                    <ul>
                        <li>
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfXMedFXSQ-_BV3osgoNM7StUnSoy9EotFVzXcrQmGCTX3rkg/viewform?usp=sf_link"
                                target="_blank"
                                >Get started here!</a
                            >
                        </li>
                    </ul>
                    All users are encourveterand to participate in our survey whether you have used the Trade-Raid
                    service or not. This reward can be received in combination with other Rewards or Trade-Raid orders.
                </div>
                <div class="space"></div>
                <div class="space"></div>
                <div class="space"></div>
                <hr />
            </section>
            <!-- <hr /> -->
            <section>
                <div class="space"></div>
                <!-- <p data-v-1ca70176="" class="para-desc mx-auto text-muted" style="text-align: center; color: #fff">
                    Receive free gold rewards by completing a wide range of activities from recruiting your Friends to
                    our services to answering our Feedback Survey.
                </p> -->
            </section>
            <section>
                <div class="space"></div>
                <div class="post">
                    <div class="post-imveteran">
                        <img
                            class="epic_rewards"
                            src="https://trade-raid-com.mo.cloudinary.net/tr-news-letter-60.png"
                            alt="Newsletter imveteran"
                        />
                    </div>
                </div>
                <newsletter-signup></newsletter-signup>
            </section>
        </div>
    </v-lazy>
</template>
<script>
import Comp1 from '../../components/Gleam/comp1.vue';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
export default {
    recTradeAmount: 'Giveaways, Rewards and Referrals',
    metaInfo: {
        title: 'Giveaways, Rewards and Referrals',
        meta: [
            { charset: 'utf-8' },
            {
                vmid: 'description',
                recTradeAmount: 'description',
                content:
                    'Receive absolute FREE gold directly to your characters in-game mailbox by telling your friends about Trade-Raid or answer our Feedback Survey',
            },
        ],
    },
    data() {
        return {
            isActive: false,
            column1: [
                {
                    label: 'Recruits Trade Amount',
                    field: 'recTradeAmount',
                },
                {
                    label: 'Veteran',
                    field: 'veteran',
                },
            ],
            column2: [
                {
                    label: 'Recruits Trade Amount',
                    field: 'recTradeAmount1',
                },
                {
                    label: 'Veteran',
                    field: 'veteran1',
                },
            ],
            row1: [
                { id: 1, recTradeAmount: '125.000G - 250.000G', veteran: '37.500G' },
                { id: 2, recTradeAmount: '250.001G - 500.000G', veteran: '90.000G' },
                { id: 3, recTradeAmount: '500.001G - 1.000.000G', veteran: '195.000G' },
                { id: 4, recTradeAmount: '1.000.001G - 2.000.000G', veteran: '405.000G' },
                { id: 5, recTradeAmount: '2.001.000G+', veteran: '825.000G' },
            ],
            row2: [
                { id: 1, recTradeAmount1: '5.000G - 10.000G', veteran1: '1500G' },
                { id: 2, recTradeAmount1: '10.001G - 20.000G', veteran1: '3375G' },
                { id: 3, recTradeAmount1: '20.001 - 40.000G', veteran1: '7600G' },
                { id: 4, recTradeAmount1: '40.001G - 80.000G', veteran1: '17.100G' },
                { id: 5, recTradeAmount1: '80.0001G+', veteran1: '38.500G' },
            ],
            realms: [
                {
                    headline1: '',
                    headline2: 'Veteran',
                },
                {
                    headline1: 'Popular Factions',
                    headline2: 'All Factions',
                },
            ],
        };
    },
    components: {
        Comp1,
        VueGoodTable,
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (max-width: 600px) {
    .post-imveteran {
        display: none;
    }
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.rounded {
    border-radius: 6px !important;
}
.rounded {
    border-radius: 0.25rem !important;
}
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .post-imveteran > a > img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        width: 100%;
        height: auto;
    }
    .post-imveteran > img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        width: 100%;
        height: auto;
    }
    .post {
        width: 100%;
        height: auto;
    }
    .wrapper {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .list-container {
        display: flex;
        justify-items: center;
        width: 100%;
        height: auto;
    }
    .column {
        float: left;
        width: 100%;
        padding: 5px;
    }
    th,
    td {
        text-align: left;
        padding: 16px;
    }
}
@media screen and (min-width: 1200px) {
    .gleam1 {
        width: 870px;
        float: left;
    }
    .gleam2 {
        margin-left: 800px;
    }
}
@media screen and (min-width: 1200px) {
    .post-imveteran > a > img {
        right: -100px;
        width: 50%;
        height: auto;
    }
    .post-imveteran > img {
        right: -100px;
        width: 50%;
        height: auto;
    }
    .post {
        width: 50%;
        height: auto;
    }
    .wrapper {
        padding-top: 100px;
        padding-left: 100px;
        width: 95%;
    }
    .list-container {
        display: flex;
        justify-items: center;
        width: 50%;
        height: auto;
    }
    .game-type-header-1 {
        display: inline-block;
        margin-left: 10%;
    }
    .game-type-header-2 {
        display: inline-block;
        margin-left: 200px;
        margin-right: 10%;
    }
    .game-type-header-3 {
        display: inline-block;
        margin-right: 7%;
    }
    .column {
        float: left;
        width: 33%;
        padding: 5px;
    }
    th,
    td {
        text-align: left;
        padding: 16px;
    }
}
* {
    margin-top: 1000px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.epic-rewards {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
}

.classic-faction {
    background-color: #000;
    width: 398px;
    height: 310px;
    color: #f1f1f1;
    text-align: center;
    vertical-align: middle;
    line-height: 90px;
    border-radius: 5px;
}
.p {
    color: #fff;
}
.classic-faction .fat {
    margin-top: 120px;
}
.space-between-tables {
    margin-right: 10px;
    margin-left: 10px;
}
.table-headings {
    display: inline-flex;
}
hr {
    color: #17d0c1;
}
.title-left {
    left: -100px;
}

.cap-table,
td,
th {
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #333;
    text-align: left;
}

.list-container > ul {
    margin-top: 10px;
    margin-bottom: 10px;
}

.list-container > ul > li {
    list-style: none;
}

.glow-on-hover {
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    width: 50%;
    height: auto;
}

.glow-on-hover:before {
    content: '';
    /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
         */
    /* background: linear-gradient(45deg,#7a00ff, #002bff,#00FFFF,#f1f1f1,#17D0C1, #17D0C1, #f1f1f1,#ADD8E6, #17D0C1, #7a00ff, #002bff);
         */
    /* background: linear-gradient(45deg,#00FFFF,#f1f1f1,#17D0C1, #17D0C1, #f1f1f1,#ADD8E6);
          */
    background: linear-gradient(45deg, #00ffff, #00ffff, #f1f1f1, #f1f1f1, #00ffff, #f1f1f1, #f1f1f1, #f1f1f1, #f1f1f1);
    position: absolute;
    z-index: -1;
    filter: blur(5px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
.glow-on-hover:active {
    color: #000;
}
.glow-on-hover:active:after {
    background: #000;
}
.glow-on-hover:hover:before {
    opacity: 1;
}
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}
@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}
.table-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}
.recruit {
    /* background-imveteran: url('https://trade-raid-com.mo.cloudinary.net/recruit_friend.png'); */
    width: 50%;
    height: auto;
    position: absolute;
    margin-left: -700px;
    bottom: 35%;
    z-index: -1;
}
h1 {
    color: #fff;
    font-weight: bold;
}
.post {
    color: #f1f1f1;
    margin-top: 25px;
    padding-right: 15px;
}
.external-link {
    color: #17d0c1;
}
.external-link:hover {
    opacity: 0.5;
}
.post-column-dense {
    display: grid;
    grid-auto-flow: column dense;
}
section {
    width: 100%;
    height: auto;
}
.space {
    padding-top: 25px;
    padding-bottom: 25px;
}
img {
    margin-right: 50px;
    width: 50%;
    height: auto;
    position: absolute;
    border-radius: 10px 0px 0px 10px;
    background-clip: padding-box;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
}
.epic-rewards > img {
    box-shadow: 0px 0px 50px #17d0c1;
    transition: ease 0.5;
    -moz-box-shadow: 0 0 5px #17d0c1;
    -webkit-box-shadow: 0 0 5px #17d0c1;
    padding: 5px;
}
#epic-gold {
    box-shadow: 0px 0px 50px #17d0c1;
    transition: ease 0.5;
    -moz-box-shadow: 0 0 5px #17d0c1;
    -webkit-box-shadow: 0 0 5px #17d0c1;
    padding: 5px;
}
img:hover {
    box-shadow: 0px 0px 50px #17d0c1;
    transition: ease 0.5;
    -moz-box-shadow: 0 0 5px #17d0c1;
    -webkit-box-shadow: 0 0 5px #17d0c1;
    padding: 5px;
}
.fat {
    font-weight: bold;
}
video {
    padding-right: 100px;
    top: 42%;
    width: 50%;
    height: auto;
    position: absolute;
    right: -100px;
}
.post h3 {
    font-size: 25px;
    color: #fff;
    padding-bottom: 10px;
    font-weight: bold;
}
.post > h2 {
    font-size: 25px;
    color: #fff;
    padding-bottom: 10px;
    font-weight: bold;
}
.post > ul {
    margin-top: 10px;
    margin-bottom: 10px;
}
.post > ul > li {
    list-style: none;
}
ul > li > a {
    color: #17d0c1;
}
ul > li > a:hover {
    opacity: 0.5;
}
.bottom > h3 {
    color: #17d0c1;
}
.eligible {
    font-style: oblique;
    color: #fff;
    font-size: 14px;
}
.small-cursive {
    font-style: oblique;
    font-size: 12px;
    margin-top: 15px;
}
hr {
    font-family: Arial, sans-serif;
    text-align: center;
    line-height: 1px;
    height: 1px;
    font-size: 1em;
    border-width: 1px 0;
    border-style: solid;
    border-color: #f1f1f1;
    margin: 20px 10px;
    overflow: visible;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box;
}
hr:after {
    position: relative;
    display: inline-block;
    color: #999;
    display: inline;
    background-color: #20283b;
    vertical-align: middle;
    top: -8px;
    padding: 0 0.5em;
}
x:-o-prefocus,
hr:after {
    content: '';
}
.post-full-width {
    width: 100%;
}
</style>
