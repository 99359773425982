<template>
    <v-lazy
        v-model="isActive"
        :options="{
            threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
    >
        <v-col class="trade-info" cols="12">
            <v-card outlined>
                <v-card-title class="d-flex justify-space-between">
                    <div class="cost-container">
                        <span class="cost mr-1"><AnimateInteger :abbreviate="true" :value="itemsCost.raw" /> </span>
                        <Coin class="cost mr-1" kind="gold" style="width: 15px; height: auto" :include_logo="false" />
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
                        <div class="action-container d-flex justify-center">
                            <v-btn type="submit" class="trade-button" :loading="loading" :disabled="loading"
                                >Trade</v-btn
                            >
                        </div>
                        <div class="trade-form-container">
                            <h3>Trade info:</h3>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Game
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please choose your Game</span>
                                    </v-tooltip>
                                </label>
                                <v-select
                                    class="custom-input-icon"
                                    v-model="wow_version"
                                    :items="versions"
                                    placeholder="All"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="updateAutocomplete"
                                >
                                    <template v-slot:item="data">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                        </div>
                                        <div class="flag-text flex-inline nowrap">
                                            {{ data.item }}
                                            <!-- EVENTBADGE TS-INFO -->
                                            <div
                                                v-if="
                                                    eventBadges[data.item.toLowerCase()] &&
                                                    eventBadges[data.item.toLowerCase()].active
                                                "
                                                class="event-badge-ts-info"
                                                :style="{
                                                    color: eventBadges.text_color,
                                                    backgroundColor: eventBadges.background_color,
                                                }"
                                            >
                                                <div class="event-badge-ts-text">
                                                    {{ eventBadges[data.item.toLowerCase()].text }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #selection="{ item }">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                        </div>
                                        <div class="flag-text flex-inline">
                                            {{ item }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>

                            <div class="form-group" v-if="!exclude_region_field.includes(wow_version)">
                                <label for="" class="d-flex align-center">
                                    Region
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please choose your Region</span>
                                    </v-tooltip>
                                </label>

                                <v-select
                                    v-model="region"
                                    :items="regions"
                                    placeholder="Choose a region"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="updateAutocomplete"
                                >
                                    <template v-slot:item="data">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ data.item }}
                                        </div>
                                    </template>
                                    <template #selection="{ item }">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ item }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>

                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Server
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>

                                        <span>Choose the server you want your gold delivered to</span>
                                    </v-tooltip>
                                </label>

                                <v-autocomplete
                                    v-model="server"
                                    :items="serverAutocomplete"
                                    placeholder="Enter server"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :error="serverErr"
                                    :rules="[rules.required]"
                                    color="primary"
                                    append-icon=""
                                    attach
                                    @change="check_realm_faction"
                                ></v-autocomplete>
                            </div>

                            <div class="form-group" v-if="!exclude_faction_field.includes(wow_version)">
                                <label for="" class="d-flex align-center">
                                    Faction
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span>Please mention which faction you play on the chosen server</span>
                                    </v-tooltip>
                                </label>

                                <v-select
                                    v-model="faction"
                                    :items="factions"
                                    placeholder="Choose a faction"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :error="factionErr"
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="check_realm_faction"
                                >
                                    <template v-slot:item="data">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ data.item }}
                                        </div>
                                    </template>
                                    <template #selection="{ item }">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ item }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Email
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>

                                        <span>Fill in your contact email</span>
                                    </v-tooltip>
                                </label>

                                <v-text-field
                                    v-model="email"
                                    placeholder="Enter email"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :rules="[rules.required, rules.email]"
                                    color="primary"
                                ></v-text-field>
                            </div>

                            <!-- <div class="form-group">
                                <a href="/faq?q=5#ratios" target="_blank">
                                    <label for="" class="d-flex align-center">
                                        VAT Country
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    v-on="on"
                                                    v-bind="attrs"
                                                    src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                    alt="info icon"
                                                    style="margin-left: 7px"
                                                />
                                            </template>
                                            <span>Choose your VAT country. Click here for more info.</span>
                                        </v-tooltip>
                                    </label>
                                </a>
                                <v-select
                                    v-model="country"
                                    :items="countries"
                                    placeholder="United States"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :error="countryErr"
                                    :append-icon="'mdi-chevron-down'"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="check_vat_region()"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <div class="d-flex">
                                                    <v-text-field
                                                        autofocus
                                                        v-model="searchTerm"
                                                        class="custom-search"
                                                        @input="searchCountries"
                                                        label="Search"
                                                        hide-details
                                                        outlined
                                                        color="secondary"
                                                        height="20"
                                                        :clearable="searchTerm && searchTerm.length > 2"
                                                    ></v-text-field>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <p>
                                                    <bold class="fat">VAT</bold> is a tax that decreases your gold %
                                                    received. You can
                                                    <bold class="fat">freely choose a non-VAT country</bold>, if this
                                                    does not apply to you.
                                                    <a
                                                        href="/faq?q=5#ratios"
                                                        target="_blank"
                                                        style="text-decoration: underline"
                                                        >Read here for more info.</a
                                                    >
                                                </p>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </div> -->

                            <div class="form-group" v-if="!exclude_faction_field.includes(wow_version)">
                                <!-- <h6>Do you have a Coupon Code/Friend Referral?</h6>
                                <label for="" class="d-flex align-center">
                                    Coupon Code/Friend Referral
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span
                                            >Input your Coupon Code and/or Friend Referral here
                                            (CharacterName-Server-Faction). If you have both, write them separately
                                            below.
                                        </span>
                                    </v-tooltip>
                                </label> -->
                                <!-- <v-text-field
                                    id="referral"
                                    v-model="referral"
                                    placeholder="Code / Friend"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    color="primary"
                                    @change="setReferral()"
                                ></v-text-field> -->
                            </div>
                            <h3>Video guide on our Trade Service:</h3>
                            <iframe
                                class="thumbnail"
                                id="thumbnail_vid"
                                src="https://www.youtube.com/embed/kwXZZlODJzc"
                                allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                                frameBorder="0"
                            >
                            </iframe>
                            <!-- A div element for a thumbnail sized image -->
                            <!-- <a target="_blank" href="https://www.youtube.com/watch?v=kwXZZlODJzc&t">
                                <img class="thumbnail" id="thumbnail_img" src="https://trade-raid-com.mo.cloudinary.net/ts_services_all_games.png" alt="Thumbnail image" />
                            </a> -->
                        </div>
                    </v-form>
                    <p>
                        Please note that the Trade Service is in BETA. Users may find bugs and or experience calculation
                        issues trading their items. Users with large Steam Inventories of many items (500+) may
                        experience lag and delay when scanning and adding items to their trade.
                        <br />
                        <br />
                        If you need any help or have questions please read our
                        <a target="_blank" href="https://trade-raid.com/faq">FAQ</a> or contact Trade-Raid
                        <a target="_blank" href="https://trade-raid.com/support">Support</a>.
                    </p>
                </v-card-text>
            </v-card>
            <TradeAmountModal :showModal="showModal" />
        </v-col>
    </v-lazy>
</template>

<script>
import { mapGetters } from 'vuex';
import VatService from '../../utils/VatService.js';
import PriceService from '../../utils/PriceService.js';
import Coin from '../UI/SVG/coin.vue';
import { getTradeAmountTradeService, getServerListTradeService } from '../../api/services.js';
import TradeAmountModal from '../UI/modals/trade-amount.vue';
import AnimateInteger from '../UI/animateInteger';
import { toastConfig } from '../../utils/toastConfig.js';
import EventBadge from '../UI/event-badge';
export default {
    components: {
        Coin,
        TradeAmountModal,
        AnimateInteger,
        EventBadge,
    },
    computed: {
        ...mapGetters(['offerItems', 'receive', 'selectedGame', 'getSteamUserName', 'errors', 'user', 'goldBuckets']),
        itemsCost() {
            let TOTAL_PRICE = 0;
            for (const item of this.goldBuckets) {
                let EXACT_PRICE = item.price;
                if (typeof item.price === 'string') {
                    const number = item.price.split('.').join('');
                    EXACT_PRICE = parseInt(number);
                }
                TOTAL_PRICE += EXACT_PRICE;
            }
            return PriceService.calculatePrice(TOTAL_PRICE, null, null);
        },
        gamesWithSilver() {
            return PriceService.gamesWithSilver(this.$store.state.trade.wow_version);
        },
        characterName() {
            return this.receive.characterName;
        },
    },
    data: () => ({
        eventBadges: null,
        loading: false,
        countries: [],
        country: 'United States',
        factionErr: false,
        serverErr: false,
        countryHasVat: {
            data: false,
            msg: false,
        },
        isActive: false,
        VAT_MSG: false,
        referral: null,
        valid: false,
        cost: 0,
        items: [],
        showModal: false,
        realm_is_unpopular: false,
        exclude_faction_field: ['New World', 'Lost Ark', 'The Elder Scrolls Online', 'Final Fantasy XIV'],
        exclude_region_field: ['The Elder Scrolls Online'],
        wow_version: 'The War Within',
        versions: [
            'The War Within',
            'Cataclysm Classic',
            /*'Classic Era',*/ 'Season of Discovery',
            'Classic Anniversary',
        ],
        region: null,
        regions: ['EU', 'US'],
        server: null,
        serverAutocomplete: [],
        serversList: null,
        unpopular_realms: [
            {
                realm: 'Tarren Mill',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Twisting Nether',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Silvermoon',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Ravencrest',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Ravencrest',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Kazzak',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Draenor',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Stormscale',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Outland',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Sylvanas',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Ragnaros',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'EU',
            },
            {
                realm: 'Tichondrius',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'US',
            },
            {
                realm: 'Stormrage',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'US',
            },
            {
                realm: 'Proudmoore',
                faction: 'Horde',
                wow_version: 'The War Within',
                region: 'US',
            },
            {
                realm: 'Area 52',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'US',
            },
            {
                realm: 'Illidan',
                faction: 'Alliance',
                wow_version: 'The War Within',
                region: 'US',
            },
        ],
        faction: null,
        factions: ['Horde', 'Alliance'],
        email: null,
        countriesCopy: [],
        extra_gold_stash: 0,
        searchTerm: '',
        countryErr: false,
        trade_amounts: null,
        rules: {
            required: (value) => !!value || 'Is required!',
            c: (value) => !!value || 'Country is Required!',
            email: (value) => {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.';
            },
        },
    }),
    async mounted() {
        this.country = 'United States';
        this.countries = VatService.countries
            .map((c) => (c.vat > 0 ? `${c.name} (-${Math.abs(Math.floor(100 * c.vat) - 100)}% G)` : c.name))
            .sort();
        this.countriesCopy = [...this.countries];
        this.trade_amounts = (await getTradeAmountTradeService()).data;
        this.serversList = (await getServerListTradeService()).data;
        if (this.user.email) {
            this.email = this.user.email;
        }
        this.eventBadges = this.$store.state.trade.eventBadges;
    },
    methods: {
        searchCountries(e) {
            this.countryErr = false;
            if (!this.searchTerm) {
                this.countries = this.countriesCopy;
            }
            this.countries = this.countriesCopy.filter((c) => {
                return c.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
            });
            if (this.countries.length) {
                this.country = this.countries[0];
            } else {
                this.countryErr = true;
            }
        },
        generateString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        hideModal() {
            this.showModal = false;
        },
        updateAutocomplete() {
            this.$store.commit('SET_LOADING', true);

            if (this.wow_version === 'The Elder Scrolls Online') {
                this.region = 'EU';
            }
            if (this.wow_version === 'Final Fantasy XIV') {
                this.faction = ' ';
            }
            let items = [];
            if (this.wow_version && this.region) {
                const version = this.wow_version === 'Classic Era' ? 'Classic Season of Mastery' : this.wow_version;
                items = this.serversList[version][this.region];
            }
            this.serverAutocomplete = items;
            this.$store.commit('SET_RECEIVE_PARAMS', ['faction', this.faction]);

            this.$store.commit('SET_LOADING', true);
            this.$store.dispatch('setWowVersion', this.wow_version);
            this.$store.dispatch('setRegion', this.region);
            this.$store.dispatch('clearOfferItems');
            this.$store.dispatch('getItems', this.selectedGame).then(() => {
                this.$store.commit('SET_LOADING', false);
            });
            if (this.region === 'EU') {
                this.country = 'Switzerland';
            } else {
                this.country = 'United States';
            }
        },
        check_name_id() {
            const id = $store.state.auth.user.id.toLowerCase();
            if (id.includes('trade-raid.com')) {
                return true;
            } else {
                return false;
            }
        },
        setReferral(event) {
            this.$store.commit('SET_RECEIVE_PARAMS', ['referral', this.referral]);
            // this.$store.commit('SET_REFERRAL', ['referral', true]);
        },
        check_realm_faction() {
            this.$store.commit('SET_RECEIVE_PARAMS', ['faction', this.faction]);
            if (!this.faction) {
                this.factionErr = true;
            } else {
                this.factionErr = false;
            }

            if (!this.server) {
                this.serverErr = true;
            } else {
                this.serverErr = false;
            }

            let realm_check = {
                faction: this.faction,
                realm: this.server,
                region: this.region,
                wow_version: this.wow_version,
            };
            Array.prototype.includesObj = function (obj) {
                for (let i = 0; i < this.length; i++) {
                    if (
                        JSON.stringify(this[i], Object.keys(this[i]).sort()) ===
                        JSON.stringify(obj, Object.keys(obj).sort())
                    )
                        return true;
                }
                return false;
            };
            if (this.unpopular_realms.includesObj(realm_check)) {
                this.$store.commit('SET_REALM_UNPOPULAR', ['realm_is_unpopular', true]);
                this.realm_is_unpopular = true;
            } else {
                this.$store.commit('SET_REALM_UNPOPULAR', ['realm_is_unpopular', false]);
                this.realm_is_unpopular = false;
            }
        },
        async submit() {
            // server validation for trade amounts ;
            this.loading = true;
            this.check_realm_faction();
            setTimeout(() => {
                if (this.loading) {
                    const t = toastConfig('Something is not working. Refresh the page and try again', 5000);
                    this.$toast.error(t.msg, t.options);
                    this.loading = false; // Stop loading after 5 seconds
                }
            }, 5000); // 5 seconds

            const trade_amounts = (await getTradeAmountTradeService()).data;

            if (!this.region) {
                this.loading = false;
                return false;
            }

            if (!this.server) {
                this.loading = false;
                this.serverError = true;
                return false;
            }

            if (!this.faction) {
                this.loading = false;
                return false;
            }

            if (!this.email) {
                this.loading = false;
                return false;
            }

            if (!this.characterName) {
                this.$store.commit('SET_CHARACTER_NAME', true);
                this.loading = false;
                return false;
            }

            if (!this.receive.battlePetName && this.receive.method == 'Auction House') {
                this.loading = false;
                return;
            }

            // let price = this.goldBuckets.reduce((accumulator, currentValue) => accumulator + currentValue.price, 0);

            let price = 0;
            for (const item of this.goldBuckets) {
                let EXACT_PRICE = item.price;
                if (typeof item.price === 'string') {
                    const number = item.price.split('.').join('');
                    EXACT_PRICE = parseInt(number);
                }
                price += EXACT_PRICE;
            }

            const p = PriceService.calculatePrice(price, null, null);

            if (
                (price <= trade_amounts['cataclysm classic'] && this.wow_version === 'Cataclysm Classic') ||
                (price <= trade_amounts.retail && this.wow_version === 'The War Within') ||
                (price <= trade_amounts.SoM && this.wow_version === 'Classic Era') ||
                (price <= trade_amounts['classic anniversary'] && this.wow_version === 'Classic Anniversary') ||
                (price <= trade_amounts['season of discovery'] && this.wow_version === 'Season of Discovery')
            ) {
                this.loading = false;
                this.showModal = true;
                setTimeout(() => {
                    this.showModal = false;
                }, 5000);
                return false;
            }

            if (typeof p.gold === 'string') {
                const number = p.gold.split('.').join('');
                p.gold = number;
            }

            if (this.$refs.form.validate()) {
                const tradeEntry = {
                    steam_id: this.$store.state.auth.user.steam_id,
                    offer_id: 1,
                    price: parseInt(p.raw, 10),
                    referral: this.referral ? this.referral : null,
                    email: this.email,
                    wow_version: this.wow_version,
                    region: this.region,
                    server: this.server,
                    faction: this.faction,
                    items: this.offerItems,
                    delivery_method: this.receive.method,
                    character_name: this.receive.characterName,
                    battle_pet_name: null,
                    trade_hash: this.generateString(5),
                    country: this.country,
                };
                if (this.receive.method == 'Auction House') {
                    tradeEntry.battle_pet_name = this.receive.battlePetName;
                }
                this.loading = false;

                await this.$store
                    .dispatch('trade', tradeEntry)
                    .then((res) => {
                        this.$store.commit('SET_RECEIVE_PARAMS', ['method', 'Auction House']);
                        this.$store.commit('SET_RECEIVE_PARAMS', ['characterName', null]);
                        this.$store.commit('SET_RECEIVE_PARAMS', ['battlePetName', null]);
                        this.$router.push('/trade-success');
                    })
                    .catch((e) => console.error(e));
            }
        },
    },
    watch: {
        country: {
            deep: true,
            handler(current, previous) {
                this.$store.commit('SET_COUNTRY', current);
            },
        },
        wow_version: {
            deep: true,
            handler(current, previous) {
                if (current === 'The Elder Scrolls Online') {
                    this.$store.commit('SET_RECEIVE_PARAMS', ['region', 'EU']);
                }
                this.$store.commit('SET_RECEIVE_PARAMS', ['server', null]);
            },
        },
        email: {
            deep: true,
            handler(curr, prev) {
                this.$store.commit('SET_TRADE_EMAIL', curr);
            },
        },
        region: {
            deep: true,
            handler(curr, prev) {
                this.$store.commit('SET_RECEIVE_PARAMS', ['region', curr]);
            },
        },
        faction: {
            deep: true,
            handler(curr, prev) {
                // this.$store.commit('SET_RECEIVE_PARAMS', ['faction', this.faction]);
            },
        },
        server: {
            deep: true,
            handler(curr, prev) {
                this.$store.commit('SET_RECEIVE_PARAMS', ['server', curr]);
            },
        },
    },
};
</script>

<style scoped>
/** mobile mqs */
@media screen and (max-width: 820px) {
    .cost-container {
        display: none !important;
    }
    .action-container {
        display: none !important;
    }
    .trade-button {
        display: none !important;
    }
}
</style>
<style lang="scss">
.flag-size {
    width: 10%;
    position: absolute;
}

.flag-text {
    padding-left: 35px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
}
.input .textarea .select {
    font-size: 16px;
}
.v-input .v-label {
    font-size: 17px;
}
.v-text-field {
    font-size: 17px;
}
h6 {
    color: #f1f1f1 !important;
}

.thumbnail {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.thumbnail:hover {
    box-shadow: 0 0 20px 1px #17d0c1;
}

.fat {
    font-weight: bold;
    color: #fff;
}

.trade-verified {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.trade-verified p {
    color: #f1f1f1 !important;
    margin-left: 10px;
}

.trade-verified i {
    color: #17d0c1 !important;
    font-size: 30px;
}

.trade-verified i:hover {
    opacity: 0.7;
    /* font-size: 25px ease;
   */
    cursor: pointer;
    animation: grow-refresh 1s alternate;
    -webkit-animation: grow-refresh 1s ease-out;
    -moz-animation: grow-refresh 1s ease-out;
    -o-animation: grow-refresh 1s ease-out;
}

/* @keyframes grow-refresh {
 0% {
    font-size: 25px;
     opacity: .7;
  }
  30% {
    font-size: 27px;
     opacity: .7;
  }
  100% {
    font-size: 30px;
     opacity: .7;
  }
} */

/* .trade-verified p {
  color: #f1f1f1;
  font-family: "Roboto"!important;
  margin-bottom: 10px;
}



.trade-verified:hover i {
  opacity: .5;
  cursor: pointer;
}  */

.trade-info input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
.trade-info
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.trade-info
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.trade-info .v-icon {
    color: #fff !important;
}
.trade-info .v-input__icon {
    display: flex;
    height: 15px;
    flex: 1 0 auto;
    justify-content: flex-end;
    min-width: 24px;
    width: 165px;
    right: 0;
    position: absolute;
}
.trade-info .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.trade-info .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.trade-info .v-menu__content .v-list {
    background: #555c70;
}
.trade-info .theme--light.v-input input {
    color: #fff;
}
.trade-info .v-menu__content::-webkit-scrollbar {
    width: 6px;
}
.trade-info .v-menu__content::-webkit-scrollbar-track {
    background: #43495a;
}
.trade-info .v-menu__content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
}
.v-dialog .modal.grey {
    background: #555c70;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
</style>

<style scoped lang="scss">
.row {
    height: 100%;
}
.trade-info {
    flex-basis: 100%;
    .v-card {
        height: 100%;
        border: 0px;
        background: #555c70;
    }
    .v-card__title {
        background: #555c70;
        border-radius: 2px 2px 0px 0px;
        padding: 20px 15px;
        .cost-container {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            .cost {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #ffffff;
            }
        }
    }
    p {
        text-align: left;
        font-size: 14px;
        line-height: 26px;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 3px;
        a {
            color: #fff;
        }
    }
    .v-card__text {
        height: calc(100% - 62px);
        background: #383f51;
        &::-webkit-scrollbar {
            width: 1em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
        .action-container {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-right: -16px;
            margin-left: -16px;
            padding: 30px 20px;
            .trade-button {
                font-family: Open Sans !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 13px !important;
                line-height: 18px !important;
                text-align: center !important;
                text-transform: uppercase !important;
                text-decoration: none !important;
                color: #ffffff !important;
                background: #17d0c1 !important;
                box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
                border-radius: 3px !important;
                padding: 0 30px !important;
            }
        }
        .trade-form-container {
            margin-right: -16px;
            margin-left: -16px;
            padding: 30px 20px;
            h3 {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
                color: #ffffff;
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 12px;
                    line-height: 11px;
                    color: rgba(255, 255, 255, 0.6);
                    border-radius: 4px;
                    margin-bottom: 7px;
                }
                &::deep input::placeholder {
                    font-size: 13px;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.5);
                }
                .v-input__append-inner {
                }
            }
        }
    }
}
.v-autocomplete__content > .theme--light > .theme--light > .v-list-item__content .v-list-item__title {
    color: #fff !important;
}
.event-badge-ts-info {
    height: auto;
    color: #fafafa;
    background-color: #1084b5;
    filter: blur(5);
    font-weight: bold;
    align-items: center;
    display: inline-flex;
    padding: 4px;
    font-size: 9px;
    font-weight: bold;
    line-height: 1;
    border-radius: 4px;
    font-family: Apercu Pro, system-ui;
    justify-content: center;
    align-items: center;
    display: flex;
}
.event-badge-ts-text {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}
</style>
