<template>
    <div class="steps">
        <!-- Step 1 -->
        <v-row>
            <v-col v-if="getStep === 1" class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                <h4>Where would you like to receive your gold?</h4>
                <v-row>
                    <trade-info />
                </v-row>
            </v-col>
        </v-row>

        <!-- Step 2 -->
        <v-row>
            <v-col v-if="getStep === 2" class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                <h4>Choose your items to trade for gold</h4>
                <v-row class="flex-column flex-nowrap">
                    <user-offer />
                </v-row>
                <v-row class="flex-column flex-nowrap">
                    <user-items />
                </v-row>
            </v-col>
        </v-row>

        <!-- Step 3 -->
        <v-row>
            <v-col v-if="getStep === 3" class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                <h4>Fill in delivery info and verify trade on steam</h4>
                <v-row class="flex-column flex-nowrap">
                    <user-offer />
                </v-row>
            </v-col>
            <v-col v-if="getStep === 3" class="user-offer-container" cols="12" sm="12" md="12" lg="2" xl="2">
                <v-row class="flex-column flex-nowrap">
                    <user-receive />
                </v-row>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
                    <div v-if="$store.state.trade.step === 3" class="action-container d-flex justify-center">
                        <v-btn type="submit" class="trade-button">Trade</v-btn>
                    </div>
                </v-form>
            </v-col>
        </v-row>

        <TradeAmountModal v-if="getStep === 3" :showModal="showModal" />
    </div>
</template>

<script>
import TradeInfo from '../../components/TradeInfo';
import UserItems from '../../components/UserItems';
import UserOffer from '../../components/UserOffer';
import UserReceive from '../../components/UserReceive/';
import GoldBuckets from '../../components/UserReceive/goldBuckets.vue';
import { getTradeAmountTradeService } from '../../api/services.js';
import PriceService from '../../utils/PriceService.js';
import { mapGetters } from 'vuex';
import TradeAmountModal from '../../components/UI/modals/trade-amount.vue';
import ProgressNavigation from '../ProgressNavigation';
export default {
    name: 'StepsWrapper',
    props: {},
    components: {
        TradeInfo,
        UserItems,
        UserOffer,
        UserReceive,
        TradeAmountModal,
        ProgressNavigation,
        GoldBuckets,
    },
    data() {
        return {
            country: 'United States',
            factionErr: false,
            countryHasVat: {
                data: false,
                msg: false,
            },
            referral: null,
            valid: false,
            showModal: false,
            realm_is_unpopular: false,
            trade_amounts: null,
            rules: {
                required: (value) => !!value || 'Is required!',
                c: (value) => !!value || 'Country is Required!',
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Invalid e-mail.';
                },
            },
        };
    },
    computed: {
        ...mapGetters(['offerItems', 'receive', 'selectedGame', 'getSteamUserName', 'errors', 'goldBuckets']),
        getStep() {
            return this.$store.state.trade.step;
        },
    },
    methods: {
        generateString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        async submit() {
            let price = 0;
            for (const item of this.goldBuckets) {
                let EXACT_PRICE = item.price;
                if (typeof item.price === 'string') {
                    const number = item.price.split('.').join('');
                    EXACT_PRICE = parseInt(number);
                }
                price += EXACT_PRICE;
            }
            // server validation for trade amounts ;
            const p = PriceService.calculatePrice(price, this.$store.state.trade.country, null);

            if (typeof p.gold === 'string') {
                const number = p.gold.split('.').join('');
                p.gold = number;
            }

            const tradeEntry = {
                steam_id: this.$store.state.auth.user.steam_id,
                offer_id: 1,
                price: parseInt(p.raw, 10),
                referral: this.$store.state.trade.refferal ? this.referral : null,
                email: this.$store.state.trade.trade_email,
                wow_version: this.$store.state.trade.wow_version,
                region: this.$store.state.trade.region,
                server: this.$store.state.trade.server,
                faction: this.$store.state.trade.faction,
                items: this.offerItems,
                delivery_method: this.receive.method,
                character_name: this.receive.characterName,
                battle_pet_name: null,
                trade_hash: this.generateString(5),
                country: this.country,
            };

            const trade_amounts = (await getTradeAmountTradeService()).data;

            if (!this.receive.characterName) {
                this.$store.commit('SET_CHARACTER_NAME', true);
                return false;
            }
            if (!this.$store.state.trade.battlePetName && this.receive.method == 'Auction House') {
                return;
            }
            if (
                (price <= trade_amounts.wotlk && this.$store.state.trade.wow_version === 'WOTLK Classic') ||
                (price <= trade_amounts.retail && this.$store.state.trade.wow_version === 'The War Within') ||
                (price <= trade_amounts.SoM && this.$store.state.trade.wow_version === 'Classic Era') ||
                (price <= trade_amounts['classic anniversary'] && this.wow_version === 'Classic Anniversary') ||
                (price <= trade_amounts['New World'] && this.$store.state.trade.wow_version === 'New World') ||
                (price <= trade_amounts['Lost Ark'] && this.$store.state.trade.wow_version === 'Lost Ark') ||
                (price <= trade_amounts['The Elder Scrolls Online'] &&
                    this.$store.state.trade.wow_version === 'The Elder Scrolls Online') ||
                (price <= trade_amounts['Final Fantasy XIV'] &&
                    this.$store.state.trade.wow_version === 'Final Fantasy XIV') ||
                (price <= trade_amounts['season of discovery'] && this.wow_version === 'Season of Discovery')
            ) {
                this.showModal = true;
                setTimeout(() => {
                    this.showModal = false;
                }, 3000);
                return false;
            }

            if (this.$refs.form.validate()) {
                if (this.receive.method == 'Auction House') {
                    tradeEntry.battle_pet_name = this.$store.state.trade.battlePetName;
                }

                await this.$store
                    .dispatch('trade', tradeEntry)
                    .then((res) => {
                        this.$store.commit('SET_RECEIVE_PARAMS', ['method', 'Auction House']);
                        this.$store.commit('SET_RECEIVE_PARAMS', ['characterName', null]);
                        this.$store.commit('SET_RECEIVE_PARAMS', ['battlePetName', null]);
                        this.$router.push('/trade-success');
                    })
                    .catch((e) => console.error(e));
            }
        },
    },
    watch: {
        wow_version: {
            deep: true,
            handler(current, previous) {
                if (current === 'The Elder Scrolls Online') {
                    this.$store.commit('SET_RECEIVE_PARAMS', ['region', 'EU']);
                }
                this.$store.commit('SET_RECEIVE_PARAMS', ['server', null]);
            },
        },
    },
    mounted: function () {
        this.$store.state.trade.step = 1;
    },
};
</script>
<style scoped lang="scss">
.steps {
    height: 200vh;
    color: #f1f1f1;
    justify-content: center;
    margin-top: 75px;
}
h4 {
    color: #f1f1f1 !important;
    font-weight: bold !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.trade-button {
    width: 100%;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 13px !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    text-decoration: none !important;
    color: #ffffff !important;
    background: #17d0c1 !important;
    box-shadow: 0px 0px 20px rgba(31, 223, 206, 0.4) !important;
    border-radius: 3px !important;
    padding: 0 30px !important;
}
</style>
