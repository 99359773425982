<template>
    <v-col class="user-deposit-container" cols="12">
        <v-card outlined>
            <v-card-title class="d-flex justify-center">
                <span class="card-title"
                    >I HAVE GOLD ON:
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <img
                                v-on="on"
                                v-bind="attrs"
                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                alt="info icon"
                                style="margin-left: 7px"
                            />
                        </template>
                        <span
                            >Please provide the in-game details of the server and character name you want to move gold
                            from</span
                        >
                    </v-tooltip>
                </span>
            </v-card-title>
            <v-form ref="deposit_form">
                <v-card-text>
                    <v-row no-gutters style="width: 100%">
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="form-group">
                                <v-select
                                    v-model="deposit_version"
                                    class="ml-4"
                                    :items="depos_versions"
                                    placeholder="Game"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="updateServerList"
                                >
                                    <template v-slot:item="data">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ data.item }}
                                        </div>
                                    </template>
                                    <template #selection="{ item }">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ item }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="form-group">
                                <v-select
                                    v-model="deposit_region"
                                    class="ml-4"
                                    :items="regions"
                                    placeholder="Region"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :append-icon="'mdi-chevron-down'"
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    @change="updateServerList"
                                >
                                    <template v-slot:item="data">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${data.item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ data.item }}
                                        </div>
                                    </template>
                                    <template #selection="{ item }">
                                        <div class="flex-inline">
                                            <img class="flag-size" :src="`/images/service-icons/${item}.png`" />
                                        </div>
                                        <div class="flag-text">
                                            {{ item }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                            <div class="form-group">
                                <v-autocomplete
                                    v-model="deposit_server"
                                    class="ml-4"
                                    :items="items"
                                    placeholder="Enter server"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    :rules="[rules.required]"
                                    color="primary"
                                    :menu-props="{ offsetY: true }"
                                    attach
                                    append-icon=""
                                    @change="updateDepositServerFee"
                                >
                                </v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row no-gutters style="width: 100%">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <h3 class="deposit-header">
                                Deposit Amount:
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <img
                                            v-on="on"
                                            v-bind="attrs"
                                            src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                            alt="info icon"
                                            style="margin-left: 7px"
                                        />
                                    </template>
                                    <span>Please write the amount of MMO Gold you want to deposit</span>
                                </v-tooltip>
                            </h3>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            offset-md="4"
                            lg="4"
                            offset-lg="4"
                            xl="4"
                            offset-xl="4"
                            align="center"
                            class="mb-122"
                        >
                            <div class="form-group">
                                <v-text-field
                                    type="number"
                                    ref="deposit_amount_ref"
                                    id="deposit_amount"
                                    v-model="deposit_amount"
                                    placeholder="Enter deposit amount"
                                    outlined
                                    height="36px"
                                    dense
                                    hide-details
                                    color="primary"
                                    :rules="[rules.required]"
                                    @keyup="emitInputDataDeposit"
                                >
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row no-gutters style="width: 100%">
                        <v-col cols="12" sm="12" md="5" xl="5">
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Gold Delivery Method
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span
                                            >Send the gold through the in-game mailbox to Trade-Raid's Gold
                                            Recipient</span
                                        >
                                    </v-tooltip>
                                </label>
                                <div class="form-group">
                                    <v-text-field
                                        class="custom-input-icon"
                                        v-model="deposit_delivery_method"
                                        :items="deposit_delivery_methods"
                                        placeholder="Select delivery method"
                                        outlined
                                        height="36px"
                                        dense
                                        readonly
                                        hide-details
                                        color="primary"
                                        :menu-props="{ top: true, offsetY: true }"
                                        attach
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" offset-md="2" xl="5" offset-xl="2">
                            <div class="form-group">
                                <label for="" class="d-flex align-center">
                                    Character Name
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <img
                                                v-on="on"
                                                v-bind="attrs"
                                                src="https://trade-raid-com.mo.cloudinary.net/icons/info.svg"
                                                alt="info icon"
                                                style="margin-left: 7px"
                                            />
                                        </template>
                                        <span
                                            >Fill in the name of the WoW character that will mail the Deposit Amount to
                                            Trade-Raid’s gold recipient</span
                                        >
                                    </v-tooltip>
                                </label>
                                <div class="form-group">
                                    <v-text-field
                                        v-model="deposit_character_name"
                                        outlined
                                        height="36px"
                                        dense
                                        hide-details
                                        :rules="[rules.required]"
                                        color="primary"
                                        attach
                                        append-icon=""
                                        placeholder="Character Name"
                                        @change="emitInputDataDeposit"
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-form>
        </v-card>
        <v-dialog v-model="showRetailWarbandInfo.show" persistent width="500">
            <v-card color="#555C70">
                <v-card-title class="headline modal grey text-white">Retail & Warband Banks</v-card-title>
                <v-card-text class="modal text-white">
                    With the introduction of Warband Banks in The War Within, all Retail Exchange Service Deposits must
                    be deposited on the following realms:
                    <br />
                    EU:
                    <ul>
                        <li>Draenor</li>
                        <li>Silvermoon</li>
                    </ul>
                    US:
                    <ul>
                        <li>Area 52 US</li>
                        <li>Stormrage US</li>
                    </ul>
                    This ensures we can receive your Retail gold across all Retail servers and factions. Please prepare
                    your gold deposit in your Warband Bank on an alt character on one of the above mentioned realms.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#17D0C1" class="signin-btn mr-3" @click="showRetailWarbandInfo.show = false"
                        >UnderStood</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-col>
</template>
<script>
import regions from '@/assets/json/regions.json';
// import servers from '@/assets/json/depositServers.json';
import versions from '@/assets/json/versions.json';
import depos_versions from '@/assets/json/deposit_versions.json';
import depositDeliveryMethods from '@/assets/json/depositDeliveryMethods.json';
import { getDepositServers, getTradeAmountExchange } from '../../api/services.js';

export default {
    data: () => ({
        showRetailWarbandInfo: {
            show: false,
            count: 0,
        },
        deposit_amount: 0,
        valid: false,
        cost: 0,
        min_deposit_amount: 999999,
        max_deposit_amount: 999999,
        items: [],
        thresholdValues: null,
        showModal: false,
        deposit_delivery_method: 'Mail',
        deposit_delivery_methods: [],
        deposit_character_name: null,
        receiver_character_name: null,
        versions: versions,
        depos_versions: depos_versions,
        is_delivery_method_selected: false,
        deposit_version: '',
        deposit_region: '',
        deposit_server: '',
        deposit_server_fee: 0,
        deposit_delivery_method_fee: 0,
        regions: regions,
        server: null,
        serversList: null,
        rules: {
            required: (value) => !!value || 'Is required!',
        },
    }),
    mounted: function () {
        getDepositServers()
            .then((res) => {
                this.serversList = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
        getTradeAmountExchange()
            .then((res) => {
                this.thresholdValues = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
        var deliveryMethods = depositDeliveryMethods;
        deliveryMethods = deliveryMethods.map((deliveryMethod) => {
            return deliveryMethod.name;
        });
        this.deposit_delivery_methods = deliveryMethods;
    },
    methods: {
        updateServerList() {
            if (this.deposit_version === 'Retail' && this.showRetailWarbandInfo.count < 1) {
                this.showRetailWarbandInfo.show = true;
                this.showRetailWarbandInfo.count = 1;
            }
            if (this.deposit_version && this.deposit_region) {
                // var servers = this.serversList[this.deposit_version][this.deposit_region];

                if (this.deposit_version === 'Classic Era') {
                    var servers = this.serversList['SoM'][this.deposit_region];
                } else {
                    // Update the server list based on the selected deposit version and region
                    var servers = this.serversList[this.deposit_version][this.deposit_region];
                }

                var serverWithFactions = [];

                if (this.deposit_server != '') {
                    this.deposit_server = '';
                    this.deposit_server_fee = 0;
                    this.receiver_character_name = null;
                }

                serverWithFactions.push(
                    servers.map((server) => {
                        var serverName = server.name;
                        var internalServerWithFactions = [];

                        server.faction.forEach((serverFaction) => {
                            internalServerWithFactions.push(`${serverName} - ${serverFaction.name}`);
                        });

                        return internalServerWithFactions;
                    }),
                );

                serverWithFactions = serverWithFactions[0];

                this.items = serverWithFactions
                    .toString()
                    .split(',')
                    .sort((a, b) => {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    });

                // this.min_deposit_amount = thresholdValues[this.deposit_version === 'Classic Era' ? 'SoM' : vers][this.deposit_region]['min_trade_amount'];
                // // thresholdValues[this.deposit_version][this.deposit_region]['min_trade_amount'];
                // this.max_deposit_amount = thresholdValues[this.deposit_version === 'Classic Era' ? 'SoM' : vers][this.deposit_region]['max_trade_amount'];
            }

            this.emitInputDataDeposit();
        },
        emitInputDataDeposit() {
            const thresholdValues = JSON.parse(JSON.stringify(this.thresholdValues));
            const newThresholdValues = {};
            for (const key in thresholdValues) {
                if (thresholdValues.hasOwnProperty(key)) {
                    newThresholdValues[key.toUpperCase()] = thresholdValues[key];
                }
            }

            let vers = this.deposit_version.toUpperCase();

            if (vers === 'CLASSIC ERA') {
                vers = 'SOM';
            }

            this.min_deposit_amount = newThresholdValues[vers][this.deposit_region]['min_trade_amount'];
            this.max_deposit_amount = newThresholdValues[vers][this.deposit_region]['max_trade_amount'];

            this.$emit('inputDataDeposit', {
                deposit_version: this.deposit_version,
                deposit_region: this.deposit_region,
                deposit_server: this.deposit_server,
                deposit_server_fee: this.deposit_server_fee,
                deposit_amount: this.deposit_amount,
                deposit_delivery_method: this.deposit_delivery_method,
                deposit_character_name: this.deposit_character_name,
                is_invalid_deposit_amount: this.$refs['deposit_amount_ref'].hasError,
                deposit_delivery_method_fee: this.deposit_delivery_method_fee,
                min_deposit_amount: this.min_deposit_amount,
                max_deposit_amount: this.max_deposit_amount,
                receiver_character_name: this.receiver_character_name,
            });
        },
        updateDepositServerFee() {
            if (this.deposit_server.length > 0) {
                var serverFactionArray = this.deposit_server.split(' - ');
                let dp_version = this.deposit_version;
                if (dp_version === 'Classic Era') {
                    dp_version = 'SoM';
                }
                var servers = this.serversList[dp_version][this.deposit_region];

                servers = JSON.parse(JSON.stringify(servers));

                var selectedFaction = [];

                servers.forEach((server) => {
                    if (server.name == serverFactionArray[0]) {
                        selectedFaction = server.faction.filter((faction) => {
                            return faction.name == serverFactionArray[1];
                        });
                    }
                });

                this.deposit_server_fee = selectedFaction[0].fee;

                this.receiver_character_name = selectedFaction[0].character;
            }

            this.emitInputDataDeposit();
        },
    },
};
</script>
<style>
.user-deposit-container input::placeholder {
    font-size: 13px !important;
    line-height: 18px !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}
.user-deposit-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset,
.user-deposit-container
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #555c70;
}
.user-deposit-container .v-icon {
    color: #fff !important;
}
.user-deposit-container .v-input__icon {
    display: flex;
    height: 15px;
    flex: 1 0 auto;
    justify-content: flex-end;
    min-width: 24px;
    width: 165px;
    right: 0;
    position: absolute;
}
.user-deposit-container .theme--light.v-list-item:not(.v-list-item--disabled) {
    color: #fff !important;
}
.user-deposit-container .theme--light.v-select .v-select__selection--comma {
    color: #fff;
}
.user-deposit-container .v-menu__content .v-list {
    background: #555c70;
}
.user-deposit-container .theme--light.v-input input {
    color: #fff;
}
.user-deposit-container .v-menu__content::-webkit-scrollbar {
    width: 6px;
}
.user-deposit-container .v-menu__content::-webkit-scrollbar-track {
    background: #43495a;
}
.user-deposit-container .v-menu__content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
}
.v-dialog .modal.grey {
    background: #555c70;
}
.v-dialog .modal.text-white {
    color: #fff;
    padding: 10px 20px;
    font-size: 17px;
    line-height: 35px;
    font-family: 'Roboto';
}
.v-card__text::-webkit-scrollbar {
    width: 6px;
}

.v-card__text::-webkit-scrollbar-track {
    background: #43495a;
}

.v-card__text::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
}
</style>

<style lang="scss" scoped>
.user-deposit-container {
    flex-basis: 1;

    .v-card {
        background: #383f51;
        border-radius: 3px;
        border-width: 0;
        height: 100%;

        .v-card__title {
            background: #555c70;
            padding: 20px;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;

            .card-title {
                font-weight: 600;
            }

            .items-count span {
                color: #aaadb7;
            }
        }

        .v-card__text {
            padding: 30px 20px;
            overflow: auto;

            h2 {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: rgba(255, 255, 255, 0.5);
                text-align: center;
                margin-bottom: 0;
            }

            .deposit-header {
                padding-top: 10px;
                text-align: center;
                line-height: 16px;
                color: #ffffff;
            }
            .v-divider.theme--light {
                border-color: rgba(255, 255, 255, 0.1);
            }
            .form-group {
                margin-bottom: 20px;
                label {
                    font-size: 12px;
                    line-height: 11px;
                    color: rgba(255, 255, 255, 0.6);
                    border-radius: 4px;
                    margin-bottom: 7px;
                }
                &::deep input::placeholder {
                    font-size: 13px;
                    line-height: 18px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .mb-122 {
            margin-bottom: 122px;
        }
    }
}
</style>
